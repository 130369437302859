import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../../components/Avatar/Avatar';

function PersonCell({ name, person }) {
  return (
    <span className="base-accounts__photo-wrapper">
      <Avatar person={person} />
      {name}
    </span>
  );
}

PersonCell.propTypes = {
  name: PropTypes.string.isRequired,
  person: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PersonCell;
