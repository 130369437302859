/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Menu from '../components/Menu';
import Header from '../components/Header/Header';
import Plug from '../components/Plug/Plug';
import LogIn from '../components/LogIn/LogIn';
import ProtectedRoute from './ProtectedRoute';

import globalAppStore from '../stores/globalAppStore';
import { AppRoutes, LoginRoute, DefaultRoute } from './Constants';

const Page = observer(({ currentPage, title, component }) => {
  if (globalAppStore.token) {
    const CurrentComponent = component || Plug;
    const cls = ['app'];
    if (!globalAppStore.isOpenMenu) {
      cls.push('app_menu-hidden');
    }
    return (
      <div className={cls.join(' ')}>
        <Menu currentPage={currentPage} />
        <div className="app__body">
          <div className="app__content-wrapper">
            <Header title={title} />
            <div className="app__content scrollbar">
              <CurrentComponent />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
});

const Routes = observer(() => (
  <Switch>
    <Route exact path={LoginRoute} component={LogIn} />
    {AppRoutes.map((route) => (
      <ProtectedRoute exact key={route.id} path={route.path}>
        <Page
          currentPage={route.parent || route.id}
          title={route.name}
          component={route.component}
        />
      </ProtectedRoute>
    ))}
    <Redirect to={DefaultRoute} />
  </Switch>
));

export default Routes;
