/**
 * ONLY FOR Scalar arrays
 * @param {array of string} arr1
 * @param {array of string} arr2
 */
const compareArrays = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((item) => arr2.includes(item));
};

export default compareArrays;
