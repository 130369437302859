import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './components/App/App';
import History from './routes/History';

import './utils/i18n';

async function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Router history={History}>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

render();
