import { APP_STATE_KEY } from '../constants';

const TOKEN_KEY = 'token';

function getAppState() {
  return JSON.parse(localStorage.getItem(APP_STATE_KEY)) || {};
}

function setAppState(state) {
  localStorage.setItem(APP_STATE_KEY, JSON.stringify(state));
}

export function getItem(key) {
  const state = getAppState();
  return state[key] || null;
}

export function setItem(key, val) {
  const state = getAppState();
  state[key] = val;
  setAppState(state);
}

export function removeItem(key) {
  const state = getAppState();
  delete state[key];
  setAppState(state);
}

export function getToken() {
  return getItem(TOKEN_KEY);
}

export function setToken(token) {
  setItem(TOKEN_KEY, token);
}

export function removeToken() {
  removeItem(TOKEN_KEY);
}
