import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { KEYCODE_ENTER } from '../../constants';

import './Input.scss';

function Input({
  value,
  onChange,
  label,
  error,
  isDisabled,
  className,
  multiple,
}) {
  const [isFocus, setIsFocus] = useState(false);

  const handleKeyUp = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      setIsFocus(false);
    }
  };

  const handleOnFocus = () => setIsFocus(true);

  const handleOnBlur = () => setIsFocus(false);

  return (
    <div className={classNames(
      'input-field',
      label && 'input-field_labeled',
      isFocus && 'input-field_focused',
      value && 'not-empty',
      multiple && 'input-field_multiple',
      !isFocus && error && value && 'invalid',
    )}
    >
      {label && (
        <span className="input-field__label">{label}</span>
      )}
      {multiple ? (
        <textarea
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          className={classNames('input-field__textarea', className)}
        />
      ) : (
        <input
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          className={classNames('input-field__input', className)}
        />
      )}
    </div>
  );
}

Input.defaultProps = {
  label: '',
  error: false,
  isDisabled: false,
  className: '',
  multiple: false,
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  multiple: PropTypes.bool,
};

export default Input;
