import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { ReactComponent as IconSpinner } from '../../icons/svg/spinner.svg';
import {
  KEYCODE_ENTER, LANGUAGES, PHONE_MASK_RU, PHONE_MASK_HE,
} from '../../constants';
import './PhoneInput.scss';

function PhoneInput({
  label, value, type, autofocus, error, loading,
  onChange, onSubmit, onChangeType,
}) {
  const [isFocus, setIsFocus] = useState(false);

  const handleChange = (e) => {
    onChange(e.target.value.replace(/\D/g, ''));
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      if (!error) {
        onSubmit();
      }
    }
  };

  const handleOnFocus = () => setIsFocus(true);

  const handleOnBlur = () => {
    setIsFocus(false);
    if (!error) {
      onSubmit();
    }
  };

  const handleLangClick = () => onChangeType(type === LANGUAGES.HE ? LANGUAGES.RU : LANGUAGES.HE);

  const getLangIcon = () => {
    if (type === LANGUAGES.HE) {
      return <span className="header__icon-flag header__icon-flag_isr" />;
    }

    return <span className="header__icon-flag header__icon-flag_rus" />;
  };

  return (
    <div
      className={classNames(
        'phone-field',
        label && 'phone-field_labeled',
        isFocus && 'phone-field_focused',
        value && 'not-empty',
        !isFocus && error && value && 'invalid',
      )}
    >
      {label && <span className="phone-field__label">{label}</span>}
      <InputMask
        className="phone-field__input"
        mask={type === LANGUAGES.HE ? PHONE_MASK_HE : PHONE_MASK_RU}
        value={value}
        onChange={handleChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onKeyUp={handleKeyUp}
        autoFocus={autofocus}
        dir="ltr"
      />
      {loading
        ? <IconSpinner className="phone-field__icon-spinner" />
        : (
          <button className="phone-field__icon-button" type="button" onClick={handleLangClick}>
            {getLangIcon()}
          </button>
        )}
    </div>
  );
}

PhoneInput.defaultProps = {
  label: null,
  value: '',
  autofocus: false,
  error: false,
  loading: false,
};

PhoneInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
};

export default PhoneInput;
