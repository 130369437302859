import io from 'socket.io-client';
import { makeAutoObservable, reaction } from 'mobx';

import globalAppStore from '../globalAppStore';

import config from '../../config.json';

class WebSocketsStore {
  connection = null;

  constructor() {
    makeAutoObservable(this);
    this.connect();
    reaction(
      () => globalAppStore.token,
      () => {
        if (globalAppStore.token) {
          this.connect();
        } else {
          this.disconnect();
        }
      },
    );
  }

  connect() {
    const { token } = globalAppStore;
    const socket = io(config.API.webSocketsURL, {
      path: '/api/events/subscription',
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    this.setConnection(socket);
  }

  disconnect() {
    if (this.connection) {
      this.connection.disconnect();
    }
  }

  setConnection(connection) {
    this.connection = connection;
  }
}

export default new WebSocketsStore();
