/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route } from 'react-router-dom';
import { LoginRoute } from './Constants';

import globalAppStore from '../stores/globalAppStore';

const ProtectedRoute = observer(({ children, ...rest }) => (
  <Route
    {...rest}
    render={() => {
      if (globalAppStore.token) {
        return children;
      }
      return <Redirect to={LoginRoute} />;
    }}
  />
));

export default ProtectedRoute;
