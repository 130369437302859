import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

const ControlTabs = observer(({ store, t }) => (
  <ul className="base-accounts__list-tab">
    {store.tabs.map((tab, idx) => (
      <li
        key={tab.id}
        className={classNames(
          'base-accounts__tab',
          store.isCurrentTab(idx) && 'base-accounts__tab_active',
        )}
      >
        {store.isCurrentTab(idx) ? (
          t(tab.label)
        ) : (
          <button
            type="button"
            className="base-accounts__tab-btn"
            onClick={() => store.setCurrentTab(idx)}
          >
            {t(tab.label)}
          </button>
        )}
      </li>
    ))}
  </ul>
));

export default ControlTabs;
