import { makeAutoObservable, reaction } from 'mobx';

import webSocketsStore from './webSocketsStore';

class UnreadCommentsStore {
  unreadCommentsCount = null;

  appealId = null;

  init(id) {
    if (id) {
      this.setAppealId(id);
      this.subscribe();
    }
  }

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => webSocketsStore.connection,
      () => this.subscribe(),
    );
  }

  subscribe() {
    const self = this;
    if (webSocketsStore.connection && self.appealId) {
      webSocketsStore.connection.emit('subscribeAppealUnreadComments', self.appealId);
      webSocketsStore.connection.on('unreadAppealComments', (data) => {
        if (self.appealId === data.appeal) {
          self.setUnreadCommentsCount(data.unreadCommentsCount);
        }
      });
    }
  }

  unsubscribe() {
    webSocketsStore.connection.emit('unsubscribeAppealUnreadComments', this.appealID);
  }

  get count() {
    return this.unreadCommentsCount;
  }

  setAppealId(id) {
    this.appealId = id;
  }

  setUnreadCommentsCount(count) {
    this.unreadCommentsCount = count;
  }
}
export default UnreadCommentsStore;
