import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Table({ fields, data, handleRowClick }) {
  const { t } = useTranslation();

  const gridTemplateColumns = fields?.map((f) => f.width ?? '1fr')?.join(' ');

  return (
    <div className="table">
      <div
        className="table-grid table-grid_head"
        style={{ gridTemplateColumns }}
      >
        {fields.map(({ label }, idx) => (
          <div
            key={label}
            className={classNames(
              'table-cell table-cell_header',
              idx === 0 && 'table-cell_first',
            )}
          >
            {t(label)}
          </div>
        ))}
      </div>
      <div className="table-grid table-grid_body">
        {Object.keys(data)?.map((id, rowIdx) => (
          <div
            key={id}
            role="button"
            aria-hidden
            className="table-grid table-grid_row"
            style={{ gridTemplateColumns }}
            onClick={() => handleRowClick(id)}
          >
            {fields.map((field, idx) => (
              <div
                key={field.name}
                className={classNames(
                  'table-cell',
                  idx === 0 && 'table-cell_first',
                  rowIdx === data.length - 1 && 'table-cell_last-row',
                )}
              >
                {data[id][field.name]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

Table.defaultProps = {
  fields: [],
  data: {},
};

Table.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handleRowClick: PropTypes.func.isRequired,
};

export default Table;
