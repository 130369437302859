import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './Select.scss';

function Select({
  label,
  value,
  valueList = [],
  isDisabled,
  className,
  size,
  handleChange,
}) {
  const { t } = useTranslation();
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(e) {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const handleClick = () => setIsOpen(!isOpen);

  const handleSelect = (selectedValue) => {
    if (selectedValue.id !== (value?.id ?? value)) {
      handleChange(selectedValue);
    }
    setIsOpen(false);
  };

  const getValue = () => valueList.find((v) => v.id === value)?.label ?? '';

  return (
    <div
      className={classNames(
        'select-button__wrapper',
        size && `select-button__wrapper_${size}`,
        className,
      )}
      ref={selectRef}
    >
      <button
        type="button"
        disabled={isDisabled}
        onClick={handleClick}
        className={classNames(
          'select-button',
          isOpen && 'is-open',
          label && 'select-button_labeled',
          (value?.id || getValue()) && 'not-empty',
        )}
      >
        {label && <span className="select-button__label">{t(label)}</span>}
        <div className="select-button__input">
          {t(value?.label ?? getValue())}
        </div>
      </button>
      {isOpen && (
        <div className="select-button__dropbox scrollbar">
          <ul className="list">
            {valueList.map((item) => (
              <li className="list__item-wrapper" key={item.id}>
                <button
                  type="button"
                  className="list__item"
                  disabled={item.id === (value?.id ?? value)}
                  onClick={() => handleSelect(item)}
                >
                  {t(item?.label)}
                </button>
              </li>
            ))}
            {!valueList.length && (
              <li className="list__item-wrapper">
                <button
                  type="button"
                  className="list__item is-empty"
                  onClick={handleClick}
                >
                  пусто
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

Select.defaultProps = {
  value: {},
  label: '',
  isDisabled: false,
  className: '',
  size: '',
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  valueList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default Select;
