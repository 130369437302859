import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Calendar from '../../UI/Calendar/Calendar';
import MultiSelect from '../../MultiSelect';
import Tooltip from '../../Tooltip';
import { STATUSES } from '../../../constants';

const SELECT_ALL_KEY = 'all';

const DEFAULT_FILTER = {
  category: 'category',
  status: Object.keys(STATUSES),
  search: '',
  createdAt: {
    from: '',
    to: '',
  },
};

const Filters = ({
  fetchFilteredAppeals,
}) => {
  const [filters, setFilters] = useState(DEFAULT_FILTER);

  const [isOverdueAppealsFilter, setIsOverdueAppealsFilter] = useState(false);

  const [statusFilterValue, setStatusFilterValue] = useState(null);

  const [dateFilterRange, setDateFilterRange] = useState({
    startRange: null,
    endRange: null,
  });

  const { t } = useTranslation();

  const keyPressHandler = async (event) => {
    if (event.key === 'Enter') {
      await fetchFilteredData();
    }
  };

  const handlerChangeStatus = async (status) => {
    await fetchFilteredData({ status });
  };

  const handlerInputChange = (event) => {
    setFilters((prev) => ({ ...prev, search: event.target.value }));
  };

  const getFormattedDate = (date) => moment(date).utc().format('YYYY-M-D HH:mm');

  const overdueAppealsHandler = async () => {
    if (!isOverdueAppealsFilter) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 2);

      const createdAt = {
        to: getFormattedDate(endDate),
      };

      await fetchFilteredData({ status: ['new'], createdAt, search: '' });

      setStatusFilterValue(['new']);

      setDateFilterRange({
        startRange: null,
        endRange: null,
      });

      setIsOverdueAppealsFilter(true);
    } else {
      await fetchFilteredData(DEFAULT_FILTER);

      setStatusFilterValue(Object.keys(STATUSES));

      setIsOverdueAppealsFilter(false);
    }
  };

  const overdueAppealsIconClasses = () => {
    if (isOverdueAppealsFilter) {
      return 'filters__icon-hours filters__icon-hours_active';
    }
    return 'filters__icon-hours';
  };

  const fetchFilteredData = async (field = {}) => {
    let newFilters;
    if (isOverdueAppealsFilter) {
      newFilters = { ...DEFAULT_FILTER, ...field };

      if (filters.search) {
        newFilters.search = filters.search;
      }

      setIsOverdueAppealsFilter(false);

      if (!field.status) {
        setStatusFilterValue(Object.keys(STATUSES));
      }
    } else {
      newFilters = { ...filters, ...field };
    }
    const {
      category, status, search, createdAt,
    } = newFilters;
    const filter = {};
    if (category !== 'category' && category !== 'all') {
      filter.category = category;
    }
    filter.status = status;
    filter.searchStr = search.trim();
    if (createdAt.from !== '') {
      filter.createdAt = {};
      filter.createdAt.from = createdAt.from;
      filter.createdAt.to = createdAt.to;
    }

    setFilters(newFilters);
    await fetchFilteredAppeals({ filter });
  };

  return (
    <div className="filters">
      <div className="filters__wrapper">
        <Calendar
          fetchFilteredData={fetchFilteredData}
          dateFilterRange={dateFilterRange}
        />

        <MultiSelect
          label={t('Status')}
          values={filters.status}
          options={STATUSES}
          SELECT_ALL_KEY={SELECT_ALL_KEY}
          SELECT_ALL_LABEL={t('All statuses')}
          onSelect={handlerChangeStatus}
          currentSelected={statusFilterValue}
        />

        <Tooltip title={t('Expired requests')}>
          <button onClick={overdueAppealsHandler} type="button" className="filters__overdue-filter">
            <span className={overdueAppealsIconClasses()} />
          </button>
        </Tooltip>
      </div>

      <Link to="/new-appeal" className="filters__button-border-less" type="button">
        {t('Create a request')}
      </Link>

      <div className="filters__search">
        <span className="filters__icon-search" />
        <input type="text" placeholder={t('Search')} className="filters__input" value={filters.search} onChange={handlerInputChange} onKeyUp={keyPressHandler} />
      </div>
    </div>
  );
};

Filters.propTypes = {
  fetchFilteredAppeals: PropTypes.func.isRequired,
};

export default Filters;
