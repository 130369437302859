import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Avatar from '../../Avatar';

import getFormattedDate from '../../../utils/getFormattedDate';
import getFormattedTime from '../../../utils/getFormattedTime';
import getFormattedFileName from '../../../utils/getFormattedFileName';

const getFormattedDateAndTime = (dateString, t) => {
  const date = new Date(dateString);
  const currentDate = new Date();

  if (
    date.getFullYear() === currentDate.getFullYear()
    && date.getMonth() === currentDate.getMonth()
    && date.getDate() === currentDate.getDate()) {
    return `${t('Today at')} ${getFormattedTime(date)}`;
  }
  return `${getFormattedDate(date)} ${t('in')} ${getFormattedTime(date)}`;
};

const Popup = ({
  popupVisible, imageUrl, closePopup, options,
}) => {
  const { t } = useTranslation();

  const getStatementVisiblePopUp = () => {
    if (popupVisible) {
      return 'popup__image popup__image_visible';
    }
    return 'popup__image';
  };

  const getStatementVisibleShadow = () => {
    if (popupVisible) {
      return 'popup__shadow popup__shadow_visible';
    }
    return 'popup__shadow';
  };

  const getPerson = () => {
    const person = {
      firstname: options.firstname ?? '',
      lastname: options.lastname ?? '',
    };

    if (options.avatarURL) {
      person.photo = {};
      person.photo.thumb = options.avatarURL;
    }
    return person;
  };

  return (
    <>
      <div className={getStatementVisiblePopUp()}>
        <div className="popup__title-wrapper">
          <div className="popup__title">
            <div className="popup__avatar-wrapper">
              <Avatar person={getPerson()} />
            </div>
            <div className="popup__file-name">{getFormattedFileName(options.fileName)}</div>

            <div className="popup__user-name">
              {options.firstname}
              &nbsp;
              {options.lastname}
              &nbsp;
              {getFormattedDateAndTime(options.createdAt, t)}
            </div>
          </div>

          <span className="popup__icon-remove" onClick={closePopup} aria-hidden />
        </div>

        <div className="popup__image-wrapper">
          <img src={imageUrl} alt="" />
        </div>

      </div>

      <div className={getStatementVisibleShadow()} onClick={closePopup} aria-hidden />
    </>
  );
};

Popup.defaultProps = {
  imageUrl: '',
};

Popup.propTypes = {
  popupVisible: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Popup;
