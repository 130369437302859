import {
  action, computed, flow, makeObservable, observable,
} from 'mobx';
import moment from 'moment';
import {
  doRequest, doPost, doPatch, doUploadFiles,
} from '../../requests/if_api/IFApi';
import {
  LANGUAGES, RESPONSE_STATUS_CODES, ACCOUNT_IMAGE_QUALITY, ACCOUNT_IMAGE_DEFAULT_MIME_TYPE,
  PHONE_CODE_RU,
} from '../../constants';

import authStore from '../authStore';
import BaseFormStore from '../commonComponents/baseFormStore';
import globalAppStore from '../globalAppStore';

const ACCOUNT_ROLES = {
  ADMIN: 'admin', MODERATOR: 'moderator',
};

const ACCOUNT_MODES = {
  NEW: 'new', EDIT: 'edit',
};

const ACCOUNT_FIELDS = [
  'status', 'firstname', 'lastname', 'email', 'phone', 'passport', 'address', 'birthDate',
];

class CreateAccountStore extends BaseFormStore {
  requiredFields = ACCOUNT_FIELDS;

  accountFields = [...ACCOUNT_FIELDS, 'password'];

  phoneType = LANGUAGES.HE;

  formData = {};

  user = {};

  role = ACCOUNT_ROLES.ADMIN;

  mode = ACCOUNT_MODES.NEW;

  isRequestLoading = false;

  errors = null;

  constructor(props) {
    super(props);
    makeObservable(this, {
      phoneType: observable,
      formData: observable,
      user: observable,
      mode: observable,
      isRequestLoading: observable,
      errors: observable,
      setUser: action,
      setMode: action,
      setErrors: action,
      setPhoneType: action,
      getFormData: computed,
      saveUser: flow,
      loadUser: flow,
    });

    this.initForm();

    this.uploadImage = this.uploadImage.bind(this);
  }

  isError(field) {
    if (field === 'birth_date') {
      return this.errors?.includes(field) || !this.checkField('birthDate');
    }
    return this.errors?.includes(field) || !this.checkField(field);
  }

  get getFormData() {
    return this.formData;
  }

  get isValidForm() {
    const isValidPassword = this.mode === ACCOUNT_MODES.EDIT || this.formData.password || '';
    return isValidPassword && super.isFormValid;
  }

  setUser(user) {
    this.user = user;
  }

  setMode(mode) {
    this.mode = mode;
  }

  setErrors(errors) {
    this.errors = errors;
  }

  setPhoneType(phoneType, isClearPhone = true) {
    this.phoneType = phoneType;
    if (isClearPhone) {
      this.setValue('phone', '');
    }
  }

  setRequestLoading(loading) {
    this.isRequestLoading = loading;
  }

  * saveUser(id) {
    this.setRequestLoading(true);

    this.setErrors(null);

    const options = {
      ...this.formData,
      role: this.role,
      phone: this.formatPhoneNumber(),
      locale: globalAppStore.lang,
      birthDate: moment(this.formData.birthDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    };

    if (!options.password) {
      delete options.password;
    }

    let result = {};

    try {
      if (id) {
        const updatedOptions = this.getUpdatedOptions(this.user, options);
        result = yield doPatch(`users/${id}`, updatedOptions);
      } else {
        result = yield doPost('users', options);
      }

      if (result.status === RESPONSE_STATUS_CODES.SUCCESSFUL_RESPONSE
          || result.status === RESPONSE_STATUS_CODES.SUCCESS) {
        if (authStore.loggedInUserId === result.data._id) {
          authStore.setUser(result.data);
        }
        this.setRequestLoading(false);
        return true;
      }
    } catch (error) {
      if (error?.response?.status === RESPONSE_STATUS_CODES.BAD_DATA) {
        let errors = error?.response?.data?.errors;
        if (errors) {
          errors = errors.replace(/Incorrect /gi, '').split(', ');
          this.setErrors(errors);
        }
      }
    }

    this.setRequestLoading(false);
    return false;
  }

  * loadUser(id) {
    this.setRequestLoading(true);

    if (id) {
      try {
        const result = yield doRequest(`users/${id}`);

        if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
          this.setUser(result.data);
          this.setMode(ACCOUNT_MODES.EDIT);
          this.requiredFields.forEach((field) => {
            if (field === 'phone' && this.user[field][1] === PHONE_CODE_RU) {
              this.setPhoneType(LANGUAGES.RU, false);
            }
            if (field === 'birthDate') {
              this.setValue(field, moment(this.user.birth_date).format('DD.MM.YYYY'));
            } else {
              this.setValue(field, this.user[field] || '');
            }
            this.setIsEdited(false);
          });
        }
      } catch (error) {
        console.log('error', error?.response);
      }
    }
    this.setRequestLoading(false);
  }

  uploadImage(image) {
    if (image && this.user._id) {
      image.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'image.jpeg');
        const headers = { 'Content-Type': 'multipart/form-data' };
        const result = await doUploadFiles(`users/${this.user._id}/images`, formData, { headers });

        if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
          this.setUser({
            ...this.user,
            photo: result.data.photo,
          });
          if (authStore.loggedInUserId === this.user._id) {
            authStore.setUser(this.user);
          }
        }
      }, ACCOUNT_IMAGE_DEFAULT_MIME_TYPE, ACCOUNT_IMAGE_QUALITY);
    }
  }

  initForm() {
    this.accountFields.forEach((field) => {
      this.formData[field] = '';
    });
  }

  formatPhoneNumber() {
    if (!this.formData?.phone) {
      return '';
    }
    return `+${this.formData?.phone.toString().replace(/\D/g, '')}`;
  }
}

export default CreateAccountStore;
