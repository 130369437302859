import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CreateAppealStore, { FORM_MODES, DEFAULT_APPEAL_CATEGORY } from '../../stores/views/createAppealStore';
import PhoneInput from '../../components/PhoneInput';
import DateInput from '../../components/DateInput';
import PassportInput from '../../components/PassportInput';
import Input from '../../components/Input';
import CategorySelect from '../../components/Appeal/CategorySelect';
import UploadImages from './components/UploadImages';
import Loader from '../../components/UI/Loader/Loader';
import Alert from '../../components/Alert';

import './NewAppealView.scss';

const NewAppealView = observer(() => {
  const { t } = useTranslation();
  const [store] = React.useState(() => new CreateAppealStore());

  const [isResizingImages, setIsResizingImages] = useState(false);

  const [alertMessages, setAlertMessages] = React.useState([]);

  const history = useHistory();

  const handleClientChange = (field) => (val) => store.setClientValue(field, val);
  const handleAppealChange = (field) => (val) => store.setAppealValue(field, val);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = await store.submitForm();
    if (id) {
      history.push(`/appeals/${id}`);
    } else {
      setAlertMessages([{ text: t('Error creating appeal'), id: Math.random().toString() }]);
    }
  };

  const isClientInputDisabled = store.mode === FORM_MODES.PHONE_INPUT || store.isClient;
  const isAppealInputDisabled = store.mode !== FORM_MODES.APPEAL_INPUT;
  const clientData = store.isClient ? store.getClient : store.clientData;

  return (
    <>
      <form className="create-statement" onSubmit={handleSubmit}>
        <h3 className="create-statement__title">{t('New ticket')}</h3>
        <div className="create-statement__controls-wrapper">
          <PhoneInput
            label={t('Phone number')}
            value={store.phone}
            type={store.phoneType}
            autofocus
            isValid={store.isValidPhone}
            error={store.isError('phone')}
            loading={store.client.isLoading}
            onChange={(phone) => store.setPhone(phone)}
            onSubmit={() => store.loadClient()}
            onChangeType={(type) => store.setPhoneType(type)}
          />

          <Input
            label={t('Surname')}
            value={clientData.lastname ?? ''}
            error={store.isError('lastname')}
            onChange={handleClientChange('lastname')}
            isDisabled={isClientInputDisabled}
          />

          <Input
            label={t('Name')}
            value={clientData.firstname ?? ''}
            error={store.isError('firstname')}
            onChange={handleClientChange('firstname')}
            isDisabled={isClientInputDisabled}
          />

          <Input
            label={t('Email')}
            value={clientData.email ?? ''}
            error={store.isError('email')}
            onChange={handleClientChange('email')}
            isDisabled={isClientInputDisabled}
          />

          <Input
            label={t('Residential address')}
            value={clientData.address ?? ''}
            error={store.isError('address')}
            onChange={handleClientChange('address')}
            isDisabled={isClientInputDisabled}
          />

          <PassportInput
            label={t('Teudat Zehut number')}
            value={clientData.passport ?? ''}
            isValid={store.isValidPassport}
            error={store.isError('passport')}
            onChange={handleClientChange('passport')}
            isDisabled={isClientInputDisabled}
          />

          <DateInput
            label={t('Date of birth')}
            value={clientData.birthDate ?? moment(clientData.birth_date).format('DD.MM.YYYY') ?? ''}
            isValid={store.isValidDate}
            error={store.isError('birth_date')}
            onChange={handleClientChange('birthDate')}
            isDisabled={isClientInputDisabled}
          />

          {!DEFAULT_APPEAL_CATEGORY && (
            <CategorySelect
              size="w100"
              value={store.appealData.category}
              onChange={handleAppealChange('category')}
              isDisabled={isAppealInputDisabled}
            />
          )}

          <Input
            label={t('Specify an address')}
            value={store.appealData.address ?? ''}
            onChange={handleAppealChange('address')}
            isDisabled={isAppealInputDisabled}
          />

          <Input
            label={t('Description of the problem')}
            value={store.appealData.description ?? ''}
            onChange={handleAppealChange('description')}
            multiple
            isDisabled={isAppealInputDisabled}
          />
        </div>

        <UploadImages
          store={store}
          client={store.isClient ? store.getClient : store.clientData}
          isResizingImages={isResizingImages}
          setIsResizingImages={setIsResizingImages}
          setAlertMessages={setAlertMessages}
        />

        <button
          type="submit"
          onClick={handleSubmit}
          disabled={!store.isValidForm || store.isRequestLoading || isResizingImages}
          className="button create-statement__button"
        >
          {t('Create')}
        </button>
      </form>

      {store.isRequestLoading && <Loader />}

      <Alert
        messages={alertMessages}
      />
    </>
  );
});

export default NewAppealView;
