import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { PASSPORT_MASK } from '../../constants';

import './PassportInput.scss';

function PassportInput({
  label,
  value,
  error,
  isDisabled,
  onChange,
}) {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div
      className={classNames(
        'passport-field',
        label && 'passport-field_labeled',
        isFocus && 'passport-field_focused',
        value && 'not-empty',
        !isFocus && error && value && 'invalid',
      )}
    >
      {label && <span className="passport-field__label">{label}</span>}
      <InputMask
        mask={PASSPORT_MASK}
        alwaysShowMask={false}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        disabled={isDisabled}
        className="passport-field__input"
        dir="ltr"
      />
    </div>
  );
}

PassportInput.defaultProps = {
  label: null,
  value: '',
  error: false,
  isDisabled: false,
};

PassportInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default PassportInput;
