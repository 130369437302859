import {
  observable, action, computed, makeObservable,
} from 'mobx';
import moment from 'moment';
import {
  isEmail, isName, isPhone, isPassport, isDate, isAddress,
} from '../../utils/validations';

class BaseFormStore {
  requiredFields = [];

  accountFields = [];

  isEdited = false;

  getUpdatedOptions(initial, current) {
    const differences = this.accountFields.reduce((result, next) => {
      if (next === 'birthDate') {
        if (moment(initial.birth_date).format('YYYY-MM-DD') !== current[next]) {
          return { ...result, [next]: current[next] };
        }
      } else if (initial[next] !== current[next]) {
        return { ...result, [next]: current[next] };
      }
      return result;
    }, {});
    return differences;
  }

  constructor() {
    makeObservable(this, {
      isEdited: observable,
      setValue: action,
      setIsEdited: action,
      isFormValid: computed,
    });
    this.checkField = this.checkField.bind(this);
  }

  setValue(field, val) {
    if (!this.isEdited) {
      this.setIsEdited(true);
    }
    this.formData[field] = val?.target?.value ?? val;
  }

  setIsEdited(isEdited) {
    this.isEdited = !!isEdited;
  }

  get isFormValid() {
    const data = this.getFormData;
    const fieldNames = Object.keys(data);

    const isFieldsInForm = this.requiredFields.some((field) => data[field]);
    if (!isFieldsInForm) {
      return false;
    }

    return fieldNames.every(this.checkField);
  }

  checkField(field) {
    const value = this.formData?.[field]?.trim();

    switch (field) {
      case 'firstname':
        return isName(value);
      case 'lastname':
        return isName(value);
      case 'email':
        return isEmail(value);
      case 'phone':
        return isPhone(value, this.phoneType);
      case 'passport':
        return isPassport(value);
      case 'birthDate':
        return isDate(value);
      case 'address':
        return isAddress(value);
      default:
        return true;
    }
  }
}

export default BaseFormStore;
