import jwt from 'jsonwebtoken';
import { makeAutoObservable, reaction } from 'mobx';
import { doPost, doRequest } from '../requests/if_api/IFApi';
import { RESPONSE_STATUS_CODES } from '../constants';

import globalAppStore from './globalAppStore';

class Auth {
  user = null;

  loginForm = {
    email: '',
    password: '',
  }

  error = null;

  isRequestLoading = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => globalAppStore.token,
      () => this.getLoggedInUser(),
    );

    this.getLoggedInUser();
  }

  setUser(user) {
    this.user = user;
  }

  setValue(field, value) {
    this.loginForm[field] = value?.target?.value ?? value;
  }

  setError(error) {
    this.error = error;
  }

  setRequestLoading(loading) {
    this.isRequestLoading = loading;
  }

  get loggedInUserId() {
    return this.user?._id;
  }

  get isValidLoginForm() {
    return this.loginForm.email.length > 0 && this.loginForm.password.length > 0;
  }

  async signIn() {
    this.setRequestLoading(true);

    if (this.isValidLoginForm) {
      try {
        const result = await doPost('users/login', this.loginForm);

        if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
          const token = result.headers?.authorization?.split('Bearer ')?.[1] || null;
          globalAppStore.setToken(token);
          this.setUser(token ? result.data : null);
        } else {
          this.setError('Authorization: failed');
        }
      } catch (err) {
        if (err) {
          this.setError(err.response?.data?.errors || err.response?.data?.message || '');
        }
      }
    }

    this.setRequestLoading(false);
  }

  async getLoggedInUser() {
    if (globalAppStore.token) {
      const id = jwt.decode(globalAppStore.token)?.id;
      this.setRequestLoading(true);
      try {
        const result = await doRequest(`users/${id}`);
        if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
          this.setUser(result.data);
        }
      } catch (err) {
        if (err) {
          this.setError(err.response?.data?.errors || err.response?.data?.message || '');
        }
      }
    }

    this.setRequestLoading(false);
  }

  signOut() {
    globalAppStore.setToken(null);
    this.setUser(null);
  }
}

export default new Auth();
