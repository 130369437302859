/* eslint-disable import/prefer-default-export */

/**
 * Возвращает дату в формате dd.mm.yy HH:MM
 * @return {String}
 */

export function toDateTimeReadable(date, comma) {
  let datestring = '';
  let timestring = '';
  const formattedDate = _toDateObject(date);
  if (_isDate(formattedDate)) {
    datestring = `${_leadingZero(formattedDate.getDate())}.${_leadingZero(formattedDate.getMonth() + 1)}.${formattedDate.getFullYear().toString().substr(-2)}`;
    timestring = `${_leadingZero(formattedDate.getHours())}:${_leadingZero(formattedDate.getMinutes())}`;
  }
  if (comma) {
    return `${datestring}, ${timestring}`;
  }
  return `${datestring} ${timestring}`;
}

/**
 * Возвращает объект Date
 * @return {Object}
 */
function _toDateObject(date) {
  if (!(date instanceof Date)) {
    return new Date(date);
  }
  return date;
}

/**
 * Проверяет является ли полученное значение датой
 * @return {Boolean}
 */
function _isDate(date) {
  return date && date.toString() !== 'Invalid Date' && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);
}

/**
 * Возвращает строку из двух знаков, дополненную лидирующим нулем
 * @return {String}
 */
function _leadingZero(num) {
  return (`0${num}`).slice(-2);
}
