import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Logo from '../UI/Logo/Logo';
import { ReactComponent as IconEye } from '../../icons/svg/fa-eye.svg';
import { ReactComponent as IconEyeClose } from '../../icons/svg/fa-eye-close.svg';
import { ReactComponent as IconChecked } from '../../icons/svg/checkbox-checked.svg';
import { ReactComponent as IconUnchecked } from '../../icons/svg/checkbox-unchecked.svg';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../constants';
import { DefaultRoute } from '../../routes/Constants';

import globalAppStore from '../../stores/globalAppStore';
import authStore from '../../stores/authStore';

const ERRORS = {
  'email or password is invalid': 'Email or password is invalid',
  'Incorrect email': 'Please enter a valid email address',
  UNKNOWN: 'Unknown error',
};

const LogIn = observer(() => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [isPolicy, setIsPolicy] = useState(true);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  useEffect(() => {
    if (authStore.error) {
      setMessage({ label: t(ERRORS[authStore.error] || ERRORS.UNKNOWN), type: 'error' });
    } else if (message) {
      setMessage(null);
    }
  }, [authStore.error]);

  const handleInput = (field) => (value) => authStore.setValue(field, value);

  const handleSubmit = (e) => {
    e.preventDefault();
    authStore.signIn();
  };

  const toggleVisiblePassword = () => setIsVisiblePassword((val) => !val);

  if (globalAppStore.token && !globalAppStore.isRequestLoading) {
    return <Redirect to={DefaultRoute} />;
  }

  return (
    <div className="log-in">
      <Logo />

      <form className="log-in__form" onSubmit={handleSubmit}>
        <h3 className="log-in__title">{t('Login to your account')}</h3>

        <div className="log-in__main">
          <div className="log-in__wrapper-inputs">
            <input
              type="text"
              placeholder="E-mail"
              className="log-in__input"
              value={authStore.loginForm.email}
              onChange={handleInput('email')}
            />

            <div className="log-in__password-wrapper">
              <input
                type={isVisiblePassword ? 'text' : 'password'}
                placeholder={t('Password')}
                className="log-in__input log-in__password"
                value={authStore.loginForm.password}
                onChange={handleInput('password')}
              />
              <div className="login-in__password-icon">
                {isVisiblePassword ? (
                  <IconEye onClick={toggleVisiblePassword} />
                ) : (
                  <IconEyeClose onClick={toggleVisiblePassword} />
                )}
              </div>
            </div>
          </div>

          <button
            type="button"
            className="log-in__forgot-password"
            onClick={() => setMessage({ label: t('Contact your administrator to recover your password'), type: 'info' })}
          >
            {t('Forgot your password?')}
          </button>

          <div className="log-in__policy-wrap">
            <button
              type="button"
              className="log-in__policy-button"
              onClick={() => setIsPolicy((val) => !val)}
            >
              {isPolicy ? <IconChecked /> : <IconUnchecked />}
            </button>
            <div className="log-in__policy-link">
              <Trans i18nKey="Accept privacy policy and terms of use">
                LABEL
                <a className="log-in__policy-link_red" href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">LINK</a>
                <a className="log-in__policy-link_blue" href={TERMS_OF_USE_LINK} target="_blank" rel="noreferrer">LINK</a>
              </Trans>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="log-in__button button"
          disabled={!authStore.isValidLoginForm || authStore.isRequestLoading || !isPolicy}
        >
          {t('Enter')}
        </button>

        {message && (
          <span className={`log-in__message log-in__message_${message?.type || 'error'}`}>
            {t(message?.label)}
          </span>
        )}
      </form>
    </div>
  );
});

export default LogIn;
