import { doUrlRequest } from './IFApi';

const getContentType = (url) => {
  const extension = url.slice(url.lastIndexOf('.') + 1);
  switch (extension) {
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    default:
      return 'image/jpeg';
  }
};

const getImageBlobUrl = async (url) => {
  if (!url) {
    return null;
  }

  const contentType = getContentType(url);
  const options = {
    headers: { 'Content-Type': contentType },
    responseType: 'arraybuffer',
  };

  const result = await doUrlRequest(url, options);
  const blob = new Blob([Buffer.from(result.data)], {
    type: contentType,
  });

  return URL.createObjectURL(blob);
};

export default getImageBlobUrl;
