import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import AppealStatus from '../../../../components/AppealStatus';
import TABLE_FIELDS from './constants';
import { categories } from '../../../../constants';

const renderValue = (t, row, { name, fields }) => {
  switch (name) {
    case 'createdAt':
      return (
        <span className="table__color-gray">
          {moment(row[fields]).format('DD.MM.YYYY')}
        </span>
      );
    case 'category':
      return t(categories[row[fields]]);
    case 'status':
      return <AppealStatus status={row[fields]} />;
    default:
      return row[fields];
  }
};

const mapData = (t, data) => data?.reduce(
  (res, row) => ({
    ...res,
    [row._id]: TABLE_FIELDS.reduce(
      (result, field) => ({
        ...result,
        [field.name]: renderValue(t, row, field),
      }),
      {},
    ),
  }),
  {},
);

const AccountAppeals = observer(({ data }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Table
      fields={TABLE_FIELDS}
      data={mapData(t, data)}
      handleRowClick={(id) => history.push(`/appeals/${id}`)}
    />
  );
});

export default AccountAppeals;
