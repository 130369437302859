import { makeAutoObservable } from 'mobx';
import { doRequest } from '../../requests/if_api/IFApi';
import { RESPONSE_HEADERS_TOTAL_COUNT, RESPONSE_STATUS_CODES } from '../../constants';

class DataStore {
  data = [];

  url = '';

  listeners = {};

  filters = {};

  sort = {};

  expand = [];

  pagingProps = {};

  currentPage = 1;

  total = 0;

  isLoading = false;

  constructor(config = {}) {
    this.data = config.data ?? [];
    this.url = config.url;
    this.listeners = config.listeners || {};
    this.filters = config.filters || {};
    this.sort = config.sort || {};
    this.expand = config.expand || [];

    if (config.pagingProps) {
      this.pagingProps = {
        limit: config.pagingProps.limit ?? 5,
        offset: config.pagingProps.offset ?? 0,
      };
    }

    makeAutoObservable(this);

    if (config.loadOnCreate) {
      this.load();
    }
  }

  get getData() {
    return this.data;
  }

  get getTotalPages() {
    if (!this.total || !this.pagingProps?.limit || this.pagingProps?.limit === 0) {
      return 0;
    }

    return Math.ceil(this.total / this.pagingProps.limit);
  }

  get getCurrentPage() {
    return this.currentPage;
  }

  setData(data) {
    this.data = data || [];
  }

  setUrl(url) {
    this.url = url;
  }

  setSort(sort, skipLoad = false) {
    this.sort = sort;

    if (!skipLoad) {
      this.load();
    }
  }

  setFilters(filters, skipLoad = false) {
    this.filters = filters;

    if (!skipLoad) {
      this.load();
    }
  }

  setTotal(total) {
    this.total = total;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  loadPage(page) {
    this.currentPage = page;
    this.pagingProps = {
      ...this.pagingProps,
      offset: (page - 1) * this.pagingProps?.limit,
    };
    this.load();
  }

  async load() {
    if (!this.url) {
      return;
    }

    let data = [];

    this.setLoading(true);

    const params = {
      ...this.filters,
      ...this.pagingProps,
    };
    if (Object.keys(this.sort).length) {
      params.sort = this.sort;
    }
    if (this.expand.length) {
      params.expand = this.expand;
    }
    const result = await doRequest(this.url, { params });

    if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
      data = result.data;
      this.setTotal(result?.headers?.[RESPONSE_HEADERS_TOTAL_COUNT]);
      this.tryExecuteListener('onDataLoad', [data]);
    }
    this.setData(data);
    this.setLoading(false);
  }

  tryExecuteListener(listenerName, args) {
    const listener = (this.listeners || {})[listenerName];
    if (listener) {
      listener(...[this].concat(args));
    }
  }
}

export default DataStore;
