import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clientStore from '../../../../stores/clientStore';
import { getFirstLetter } from '../../../../utils/commonUtils';

const AccountAvatar = observer(() => {
  useEffect(() => {
    clientStore.getImage();
  }, [clientStore.client?._id]);

  if (clientStore.avatar) {
    return <img className="user__image" src={clientStore.avatar} alt="User" />;
  }

  return clientStore.client.firstname && clientStore.client.lastname ? (
    <div className="user__acronym">
      {`${getFirstLetter(clientStore.client.firstname)}${getFirstLetter(clientStore.client.lastname)}`}
    </div>
  ) : (
    <div className="user__image user__image-skeleton" />
  );
});

export default AccountAvatar;
