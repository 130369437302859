import Appeal from '../components/Appeal/Appeal';
import AccountsTable from '../views/AccountsTable';
import AccountView from '../views/AccountView';
import newAppealsCountStore from '../stores/webSockets/newAppealsCountStore';
import NewAppealView from '../views/NewAppealView';
import CreateAdminView from '../views/CreateAdminView';
import EditAccountView from '../views/EditAccountView';
import MyAppealsTable from '../views/MyAppealsTable';
import AppealsView from '../views/AppealsView';

const MenuRoutes = [
  {
    default: true,
    id: 'all_appeals',
    icon: 'message',
    name: 'All requests',
    path: '/appeals',
    component: AppealsView,
    counter: newAppealsCountStore,
  },
  {
    id: 'my_appeals',
    icon: 'statement',
    name: 'My requests',
    path: '/my_appeals',
    component: MyAppealsTable,
  },
  {
    id: 'map',
    icon: 'map',
    name: 'Map',
    path: '/map',
  },
  {
    id: 'accounts',
    icon: 'base-accounts',
    name: 'Accounts',
    path: '/users',
    component: AccountsTable,
  },
  // TODO: feature
  // {
  //   id: 'categories',
  //   icon: 'categories',
  //   name: 'Categories',
  //   path: '/categories',
  // },
];

const ChildRoutes = [
  {
    id: 'appeal',
    parent: 'all_appeals',
    path: '/appeals/:id',
    component: Appeal,
  },
  {
    id: 'my_appeal',
    parent: 'my_appeals',
    path: '/my_appeals/:id',
    component: Appeal,
  },
  {
    id: 'user',
    parent: 'accounts',
    path: '/users/:id',
    component: AccountView,
  },
  {
    id: 'edit_user',
    parent: 'accounts',
    path: '/edit_user/:id',
    component: EditAccountView,
  },
  {
    id: 'new_appeal',
    parent: 'all_appeals',
    path: '/new-appeal',
    component: NewAppealView,
  },
  {
    id: 'create_admin',
    parent: 'accounts',
    path: '/create_admin',
    component: CreateAdminView,
  },
  {
    id: 'edit_admin',
    parent: 'accounts',
    path: '/edit_admin/:id',
    component: CreateAdminView,
  },
];

const AppRoutes = [
  ...MenuRoutes,
  ...ChildRoutes.map((route) => {
    const parentRoute = MenuRoutes.find(
      (menuRoute) => menuRoute.id === route.parent,
    );
    route.name = parentRoute.name;
    return route;
  }),
];

const LoginRoute = '/login';
const DefaultRoute = MenuRoutes.find((route) => route.default).path;

export {
  MenuRoutes, AppRoutes, LoginRoute, DefaultRoute,
};
