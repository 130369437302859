import { makeAutoObservable, reaction } from 'mobx';
import i18n from 'i18next';

import DataStore from './dataStore';
import globalAppStore from './globalAppStore';

import { categories } from '../constants';

const url = 'categories';

class CategoryStore {
  categories = new DataStore({
    url,
    loadOnCreate: true,
  });

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => globalAppStore.lang,
      () => this.sortCategories(),
    );

    reaction(
      () => this.categories.getData,
      (value, previousValue) => {
        if (previousValue?.length === 0 && value?.length > 0) {
          this.sortCategories();
        }
      },
    );
  }

  get getNormalizedCategories() {
    return this.categories.getData.map((cat) => ({
      id: cat.key,
      label: categories[cat.key],
    }));
  }

  get getDepartments() {
    return this.getNormalizedCategories
      .filter(({ id }) => id !== 'other');
  }

  sortCategories() {
    const sortedArray = this.categories.getData.slice().sort((a, b) => {
      if (i18n.t(categories[a.key]) > i18n.t(categories[b.key])) return 1;
      return -1;
    });
    this.categories.setData(sortedArray);
  }
}

export default new CategoryStore();
