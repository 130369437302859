import { makeAutoObservable } from 'mobx';
import { doPatch, doRequest } from '../requests/if_api/IFApi';
import getImageBlobUrl from '../requests/if_api/ImageBlob';
import DataStore from './dataStore';
import { RESPONSE_STATUS_CODES } from '../constants';

const url = 'clients';

class ClientStore {
  clients = new DataStore({
    url,
    pagingProps: {
      limit: 5,
    },
  });

  client = {};

  avatar = null;

  isRequestLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setClient(client) {
    this.client = client;
  }

  setAvatar(avatar) {
    this.avatar = avatar;
  }

  setRequestLoading(loading) {
    this.isRequestLoading = loading;
  }

  resetClient() {
    if (this.avatar) {
      URL.revokeObjectURL(this.avatar);
      this.setAvatar(null);
    }
    this.setClient({});
  }

  async getClient(id) {
    this.setRequestLoading(true);
    const result = await doRequest(`${url}/${id}`);
    if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
      this.setClient(result.data);
    }
    this.setRequestLoading(false);
  }

  async updateClient(options) {
    this.setRequestLoading(true);

    if (this.client._id) {
      try {
        const result = await doPatch(`clients/${this.client._id}`, options);
        if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
          this.setClient(result.data);
        }
      } catch (error) {
        console.log('error', error);
      }
    }

    this.setRequestLoading(false);
  }

  async getImage() {
    if (!this.client?.photo) {
      return null;
    }

    this.setRequestLoading(true);
    const avatar = await getImageBlobUrl(this.client.photo.thumb);
    this.setAvatar(avatar);
    this.setRequestLoading(false);

    return null;
  }
}

export default new ClientStore();
