import React from 'react';
import { version } from '../../../../package.json';

const Footer = () => (
  <div className="footer">
    <div className="footer__version">
      v.
      {version}
    </div>

    <div className="footer__copyright">2021 &copy; Alfa Russia-Israel Group LTD</div>
  </div>
);

export default Footer;
