import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Input from '../../Input';
import RadioButton from '../../UI/RadioButton';
import Select from '../../Select';

import categoryStore from '../../../stores/categoryStore';

import { isEmail } from '../../../utils/validations';

import './RedirectAppealPopup.scss';

const RADIO = {
  SELECT_DEPARTMENT: { LABEL: 'Выбрать ведомство', VALUE: 0 },
  SPECIFY_ADDRESS_MANUALLY: { LABEL: 'Указать адрес вручную', VALUE: 1 },
};

const RedirectAppealPopup = observer(({
  closePopup,
  popupVisible,
  sendToDepartment,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [selectId, setSelectId] = React.useState('');
  const [radioValue, setRadioValue] = React.useState(
    RADIO.SELECT_DEPARTMENT.VALUE,
  );

  const getStatementVisiblePopUp = classNames(
    'redirect-appeal-popup',
    popupVisible && 'redirect-appeal-popup_visible',
  );

  const getStatementVisibleShadow = classNames(
    'popup__shadow',
    popupVisible && 'popup__shadow_visible',
  );

  const handleChangeRadioButton = (newRadioValue) => {
    setRadioValue(newRadioValue);

    if (RADIO.SPECIFY_ADDRESS_MANUALLY.VALUE === newRadioValue) {
      setSelectId('');
    } else {
      setInputValue('');
    }
  };

  const handleClickHidePopup = () => {
    setInputValue('');
    setSelectId('');
    setRadioValue(RADIO.SELECT_DEPARTMENT.VALUE);
    closePopup();
  };

  const handleClickSubmitButton = () => {
    const directedTo = {};

    if (selectId) {
      directedTo.department = selectId.id;
    } else {
      directedTo.email = inputValue;
    }

    sendToDepartment(directedTo);
    handleClickHidePopup();
  };

  const isFormValid = selectId || isEmail(inputValue);

  return (
    <>
      <form className={getStatementVisiblePopUp}>
        <div className="redirect-appeal-popup__title">
          Укажите ведомство или организацию
        </div>

        <div className="redirect-appeal-popup__inputs-wrapper">
          <p className="redirect-appeal-popup__description">
            Выберите ведомство или укажите электронную почту для отправки
            вручную
          </p>

          <RadioButton
            idx="select-department"
            isChecked={radioValue === RADIO.SELECT_DEPARTMENT.VALUE}
            label={RADIO.SELECT_DEPARTMENT.LABEL}
            name="RedirectAppealRadio"
            onChange={handleChangeRadioButton}
            value={RADIO.SELECT_DEPARTMENT.VALUE}
          />

          <Select
            isDisabled={radioValue === RADIO.SPECIFY_ADDRESS_MANUALLY.VALUE}
            handleChange={(id) => setSelectId(id)}
            label="Выберите ведомство"
            value={selectId}
            valueList={categoryStore.getDepartments}
          />

          <RadioButton
            idx="specify-address-manually"
            isChecked={radioValue === RADIO.SPECIFY_ADDRESS_MANUALLY.VALUE}
            label={RADIO.SPECIFY_ADDRESS_MANUALLY.LABEL}
            name="RedirectAppealRadio"
            onChange={handleChangeRadioButton}
            value={RADIO.SPECIFY_ADDRESS_MANUALLY.VALUE}
          />

          <Input
            error={!isEmail(inputValue)}
            isDisabled={radioValue === RADIO.SELECT_DEPARTMENT.VALUE}
            label="Электронная почта"
            onChange={(event) => setInputValue(event.target.value)}
            value={inputValue}
          />
        </div>

        <div className="redirect-appeal-popup__buttons-wrapper">
          <button
            className="button"
            disabled={!isFormValid}
            onClick={handleClickSubmitButton}
            type="button"
          >
            Перенаправить
          </button>

          <button
            className="button button_gray"
            onClick={handleClickHidePopup}
            type="button"
          >
            Отмена
          </button>
        </div>
      </form>

      <div
        aria-hidden
        className={getStatementVisibleShadow}
        onClick={handleClickHidePopup}
      />
    </>
  );
});

RedirectAppealPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  popupVisible: PropTypes.bool.isRequired,
  sendToDepartment: PropTypes.func.isRequired,
};

export default RedirectAppealPopup;
