export default [
  {
    name: 'no',
    label: 'Request ID',
    fields: 'no',
    width: '150px',
  },
  {
    name: 'createdAt',
    label: 'Date',
    fields: 'createdAt',
    width: '150px',
  },
  // TODO: feature
  // {
  //   name: 'category',
  //   label: 'Category',
  //   fields: 'category',
  //   width: '160px',
  // },
  {
    name: 'address',
    label: 'Address',
    fields: 'address',
    width: '350px',
  },
  {
    name: 'status',
    label: 'Status',
    fields: 'status',
    width: '1fr',
  },
];
