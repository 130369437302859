import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import formatPhoneNumber from '../../../../utils/formatPhoneNumber';
import StatusCell from '../StatusCell';
import PersonCell from '../PersonCell';
import TABLE_FIELDS from './constants';

import './Tables.scss';

const getValue = (row, { name, fields }) => {
  switch (name) {
    case 'person':
      return <PersonCell name={fields.map((f) => row[f]).join(' ')} person={row} />;
    case 'phone':
      return <span dir="ltr">{formatPhoneNumber(row[fields])}</span>;
    case 'status':
      return <StatusCell status={row[fields]} row={row} />;
    default:
      return row[fields];
  }
};

// TODO: refactor to use Table Component
const Tables = observer(({ store }) => {
  const { t } = useTranslation();
  const currentTab = store.getCurrentTab;
  const currentTabStore = currentTab.store;
  const count = TABLE_FIELDS[currentTab.value].length;

  useEffect(() => {
    if (currentTab.filters) {
      currentTabStore.setFilters(currentTab.filters);
    } else {
      currentTabStore.load();
    }
  }, [store.getCurrentTab]);

  return (
    <>
      <div
        className="table table-grid"
        style={{ gridTemplateColumns: `repeat(${count}, 1fr)` }}
      >
        {TABLE_FIELDS[currentTab.value].map(({ label }, idx) => (
          <div
            key={label}
            className={classNames(
              'table-cell table-cell_header',
              idx === 0 && 'table-cell_first',
            )}
          >
            {t(label)}
          </div>
        ))}
      </div>
      {currentTabStore.getData.map((row, rowIdx) => (
        <Link
          key={row._id}
          // FIXME: unmock link (for Users)
          to={currentTab.value === 'clients' ? `/users/${row._id}` : '/users'}
          className="table table-grid table-grid_row"
          style={{ gridTemplateColumns: `repeat(${count}, 1fr)` }}
        >
          {TABLE_FIELDS[currentTab.value].map((tableFields, idx) => (
            <div
              key={tableFields.name}
              className={classNames(
                'table-cell',
                idx === 0 && 'table-cell_first',
                rowIdx === currentTabStore.getData.length - 1 && 'table-cell_last-row',
              )}
            >
              {getValue(row, tableFields)}
            </div>
          ))}
        </Link>
      ))}
    </>
  );
});

export default Tables;
