import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Chat.scss';
import CommentInput from './CommentInput';
import Comment from './Comment';

import getFormattedDate from '../../../utils/getFormattedDate';

const sortCommentsByDate = (a, b) => new Date(a.setAt) - new Date(b.setAt);

const Chat = ({
  id, comments, sendComment, showPopup, showPDF, showAlert,
}) => {
  const windowMessages = useRef(null);

  useEffect(() => {
    windowMessages.current.scrollTop = windowMessages.current.scrollHeight;
  }, [comments]);

  const renderCommentsOneDay = (date, commentsOfOneDay) => (
    <div key={date} className="chat__one-day-messages">
      <div className="chat__date-wrapper">
        <span className="chat__date">{date}</span>
      </div>

      {commentsOfOneDay.sort(sortCommentsByDate).map((comment) => {
        const {
          createdAt, author,
        } = comment;

        return (
          <Comment
            comment={comment}
            key={`${author._id} ${createdAt}`}
            showPopup={showPopup}
            showPDF={showPDF}
          />
        );
      })}
    </div>
  );

  const renderComments = () => {
    const sortedByDateComments = {};

    comments.forEach((comment) => {
      const date = new Date(comment.createdAt);
      const field = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

      if (sortedByDateComments[field]) {
        sortedByDateComments[field].push(comment);
      } else {
        sortedByDateComments[field] = [];
        sortedByDateComments[field].push(comment);
      }
    });

    return Object.keys(sortedByDateComments)
      .map((item) => +item)
      .sort()
      .map((item) => {
        const formattedDate = getFormattedDate(new Date(item));
        return renderCommentsOneDay(formattedDate, sortedByDateComments[item]);
      });
  };

  return (
    <div className="chat">
      <div className="chat__window-messages scrollbar" ref={windowMessages}>
        {renderComments()}
      </div>

      <CommentInput sendComment={sendComment} id={id} showPopup={showPopup} showAlert={showAlert} />
    </div>
  );
};

Chat.defaultProps = {
  id: '',
  comments: [],
};

Chat.propTypes = {
  id: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.object),
  sendComment: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  showPDF: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

export default Chat;
