import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { MenuRoutes } from '../../routes/Constants';
import MenuItem from './MenuItem';
import Logo from '../UI/Logo/Logo';

import globalAppStore from '../../stores/globalAppStore';

const Menu = observer(({ currentPage }) => {
  const { t } = useTranslation();
  const clickHanlder = (event) => {
    event.preventDefault();
    globalAppStore.toggleOpenMenu();
  };
  const cls = ['aside__icon-back'];
  if (!globalAppStore.isOpenMenu) {
    cls.push('aside__icon-back_rotate');
  }

  return (
    <aside className="aside">
      <Logo />
      <nav className="aside__list-wrapper">
        <ul className="aside__list">
          {MenuRoutes.map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              path={menuItem.path}
              name={menuItem.name}
              icon={menuItem.icon}
              counter={menuItem.counter}
              isActive={currentPage === menuItem.id}
              menuVisible={globalAppStore.isOpenMenu}
            />
          ))}
        </ul>
      </nav>

      <div className="aside__back-wrapper">
        <a href="/" className="aside__back" onClick={clickHanlder}>
          <span className={cls.join(' ')} />
          {globalAppStore.isOpenMenu ? t('Hide') : ''}
        </a>
      </div>
    </aside>
  );
});

Menu.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default Menu;
