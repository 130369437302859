export const APP_STATE_KEY = 'ATIDILocalAppState';

export const RESPONSE_HEADERS_TOTAL_COUNT = 'x-total-count';

export const RESPONSE_STATUS_CODES = {
  SUCCESS: 200,
  SUCCESSFUL_RESPONSE: 201,
  BAD_DATA: 400,
  NOT_AUTHORIZED: 401,
  NOT_FOUND: 404,
  ALREADY_EXISTS: 409,
};

export const LANGUAGES = {
  RU: 'ru',
  EN: 'en',
  HE: 'he',
};

export const DEFAULT_LANGUAGE = LANGUAGES.RU;

export const categories = {
  feedback: 'Feedback',
  architecture: 'Architecture',
  safety: 'Security',
  veterinary: 'Veterinary medicine',
  yards: 'Curtilage',
  roads: 'Roads',
  it: 'Information technology',
  parks: 'Forest',
  multicenter: 'Multifunctional Public Services Center',
  medicine: 'Medicine',
  apptbuildings: 'Apartment blocks',
  garbage: 'Garbage in the dumpster area',
  regionproperty: 'Regional property',
  education: 'Education',
  publicareas: 'Public areas',
  publictransport: 'Public transit',
  ecology: 'Nature, Ecology, Agriculture',
  social: 'Social services',
  constructing: 'Construction',
  sport: 'Physical education, sports',
  buisness: 'Business and economy',
  energetics: 'Power and energy',
  other: 'Other',
};

export const STATUSES = {
  new: 'New',
  processing: 'In progress',
  working: 'Pending',
  rejected: 'Rejected',
  done: 'Done',
  finished: 'Completed',
};

export const ACCOUNT_STATUSES = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  ARCHIVED: 'archived',
};

export const ACCOUNT_COLORED_STATUSES = {
  active: { label: 'Active', color: 'green' },
  blocked: { label: 'Blocked', color: 'red' },
  archived: { label: 'Archived', color: 'gray' },
};

export const ACCOUNT_ACTIONS = {
  ACTIVATE: 'Unblock',
  BLOCK: 'Block',
};

export const KEYCODE_ENTER = 13;

export const PHONE_LENGTH = 11;

export const PHONE_HE_LENGTH = 12;

export const PHONE_MASK_RU = '+799-9-9999999';

export const PHONE_MASK_HE = '+\\972-99-9999999';

export const PHONE_CODE_RU = '7';

export const PASSPORT_LENGTH = 9;

export const PASSPORT_MASK = '9 9999999 9';

export const ACCOUNT_IMAGE_QUALITY = 0.8;

export const ACCOUNT_IMAGE_DEFAULT_MIME_TYPE = 'image/jpeg';

export const IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const DOCUMENT_MIME_TYPES = ['application/pdf'];

export const LOCALE = 'ru';

export const LOCATION = ['61.7662885831371', '34.334723443479035'];

export const MAX_FILES_TO_COMMENT = 2;

export const MAX_IMAGE_SIZE = 2048;

export const PRIVACY_POLICY_LINK = 'https://atidi.s3.eu-central-1.amazonaws.com/pdfdocs/ATIDI_Privacy+Policy.pdf';

export const TERMS_OF_USE_LINK = 'https://atidi.s3.eu-central-1.amazonaws.com/pdfdocs/Atidi_ToU.pdf';
