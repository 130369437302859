import { makeAutoObservable, reaction } from 'mobx';
import moment from 'moment';
import DataStore from '../dataStore';
import { doPost, doUploadFiles } from '../../requests/if_api/IFApi';
import {
  LOCALE, LOCATION, RESPONSE_STATUS_CODES, PHONE_LENGTH, PHONE_HE_LENGTH,
  PASSPORT_LENGTH, LANGUAGES,
} from '../../constants';

const CLIENT_FIELDS = ['firstname', 'lastname', 'email', 'address', 'passport', 'birthDate'];

const APEAL_FIELDS = ['category', 'address', 'description'];

export const FORM_MODES = {
  PHONE_INPUT: 'phone',
  APPEAL_INPUT: 'appeal',
};

export const DEFAULT_APPEAL_CATEGORY = 'other';

class CreateAppealStore {
  client = new DataStore({
    url: 'clients',
    listeners: {
      onDataLoad: () => this.setMode(FORM_MODES.APPEAL_INPUT),
    },
  });

  phone = '';

  phoneType = LANGUAGES.HE;

  clientData = {};

  appealData = {};

  images = [];

  isRequestLoading = false;

  mode = '';

  errors = [];

  constructor() {
    makeAutoObservable(this);

    this.initClient();
    this.initAppeal();

    reaction(
      () => this.isValidPhone,
      (isValid) => {
        if (!isValid) {
          this.initClient();
        }
      },
    );
  }

  isError(field) {
    return this.errors?.includes(field);
  }

  get isValidPhone() {
    const phoneLength = this.phone?.replace(/\D/g, '')?.length;
    return this.phoneType === LANGUAGES.HE
      ? phoneLength === PHONE_HE_LENGTH
      : phoneLength === PHONE_LENGTH;
  }

  get isValidPassport() {
    return this.isClient || this.clientData.passport?.replace(/\D/g, '')?.length === PASSPORT_LENGTH;
  }

  get isValidDate() {
    return this.isClient || moment(this.clientData.birthDate, 'DD.MM.YYYY', true).isValid();
  }

  get isValidClientForm() {
    return CLIENT_FIELDS.every((field) => !!this.clientData[field].trim());
  }

  get isValidAppealForm() {
    return APEAL_FIELDS.every((field) => !!this.appealData[field].trim() || (field === 'category' && DEFAULT_APPEAL_CATEGORY));
  }

  get isValidForm() {
    return (this.isClient || (this.isValidClientForm && this.isValidPassport && this.isValidDate))
      && this.isValidAppealForm
      && this.isValidPhone;
  }

  get getClient() {
    return this.client.getData.length ? this.client.getData[0] : {};
  }

  get isClient() {
    return !!this.getClient?._id;
  }

  setPhone(phone) {
    if (!this.client.isLoading) {
      this.phone = phone;
    }
  }

  setPhoneType(phoneType, isClearPhone = true) {
    this.phoneType = phoneType;
    if (isClearPhone) {
      this.setPhone('');
    }
  }

  setMode(mode) {
    this.mode = mode;
  }

  setClientValue(field, val) {
    this.clientData[field] = val?.target?.value ?? val;
  }

  setAppealValue(field, val) {
    this.appealData[field] = val?.target?.value ?? val;
  }

  setImages(images) {
    this.images = images;
  }

  setErrors(errors) {
    this.errors = [...this.errors, ...errors];
  }

  addImages(images) {
    this.setImages([...this.images, ...images]);
  }

  removeImage(image) {
    this.setImages(this.images.filter((i) => i.name !== image.name));
  }

  setRequestLoading(loading) {
    this.isRequestLoading = loading;
  }

  async uploadImages(appealId) {
    if (this.images.length) {
      const formData = new FormData();
      this.images.forEach((image) => formData.append('files', image));
      const headers = { 'Content-Type': 'multipart/form-data' };
      await doUploadFiles(`appeals/${appealId}/images`, formData, { headers });
    }
  }

  async createClient() {
    const {
      email,
      firstname,
      lastname,
      address,
      birthDate,
      passport,
    } = this.clientData;

    const options = {
      email,
      locale: LOCALE,
      firstname,
      lastname,
      phone: `+${this.phone}`,
      address,
      birthDate: moment(birthDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      passport,
    };

    try {
      const result = await doPost('clients', options);
      if (result.status === RESPONSE_STATUS_CODES.SUCCESSFUL_RESPONSE) {
        return result.data;
      }
    } catch (error) {
      if (error?.response?.status === RESPONSE_STATUS_CODES.BAD_DATA) {
        let errors = error?.response?.data?.errors;
        if (errors) {
          errors = errors.replace(/Incorrect /gi, '').split(', ');
          this.setErrors(errors);
        }
      }
      if (error?.response?.status === RESPONSE_STATUS_CODES.ALREADY_EXISTS) {
        this.setErrors(['passport']);
      }
    }

    return null;
  }

  async submitForm() {
    this.setErrors([]);

    let client = this.isClient && this.getClient;

    this.setRequestLoading(true);

    if (!client._id) {
      client = await this.createClient();
      if (!client) {
        this.setRequestLoading(false);
        return false;
      }
    }

    const { category, address, description } = this.appealData;

    const options = {
      client: client._id,
      category: DEFAULT_APPEAL_CATEGORY ?? category,
      location: LOCATION,
      address,
      description,
    };

    try {
      const result = await doPost('appeals', options);
      if (result.status === RESPONSE_STATUS_CODES.SUCCESSFUL_RESPONSE) {
        await this.uploadImages(result.data._id);
        this.setRequestLoading(false);
        return result.data._id;
      }
    } catch (error) {
      if (error?.response?.status === RESPONSE_STATUS_CODES.BAD_DATA) {
        let errors = error?.response?.data?.errors;
        if (errors) {
          errors = errors.replace(/Incorrect /gi, '').split(', ');
          this.setErrors(errors);
        }
      }
    }

    this.setRequestLoading(false);

    return false;
  }

  loadClient() {
    if (this.isValidPhone && this.mode === FORM_MODES.PHONE_INPUT) {
      this.client.setFilters({ filter: { phone: `+${this.phone}` } });
    }
  }

  initClient() {
    this.client.setData([]);

    CLIENT_FIELDS.forEach((field) => {
      this.clientData[field] = '';
    });

    this.mode = FORM_MODES.PHONE_INPUT;
  }

  initAppeal() {
    APEAL_FIELDS.forEach((field) => {
      this.appealData[field] = '';
    });
  }

  clear() {
    this.initClient();
    this.initAppeal();
  }
}

export default CreateAppealStore;
