import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { getFirstLetter } from '../../utils/commonUtils';

import imageDataStore from '../../stores/imageDataStore';

import './Avatar.scss';

const Avatar = observer(({ person, size }) => {
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => {
    if (person?.photo?.thumb) {
      setUrl(person.photo.thumb);
      imageDataStore.getImage(person.photo.thumb);
    }
  }, [person?.photo?.thumb]);

  return (
    <div className={classNames('avatar', size && `avatar_${size}`)}>
      {imageDataStore.images?.[url] ? (
        <img
          className="avatar__image"
          src={imageDataStore.images[url]}
          alt="avatar"
        />
      ) : (
        <div className="avatar__acronym">
          {`${getFirstLetter(person?.firstname)}${getFirstLetter(person?.lastname)}`}
        </div>
      )}
    </div>
  );
});

Avatar.defaultProps = {
  person: {},
  size: '',
};

Avatar.propTypes = {
  person: PropTypes.objectOf(PropTypes.any),
  size: PropTypes.string,
};

export default Avatar;
