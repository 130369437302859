import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../components/BreadCrumbs';
import AccountAvatar from './components/AccountAvatar';
import AccountInfo from './components/AccountInfo';
import AccountControls from './components/AccountControls';
import AccountAppeals from './components/AccountAppeals';
import Footer from '../../components/UI/Footer/Footer';
import Paginator from '../../components/UI/Paginator';

import clientStore from '../../stores/clientStore';
import AppealsStore from '../../stores/appealStore';

import './Account.scss';

const AccountView = observer(() => {
  const params = useParams();
  const { t } = useTranslation();

  const [appealsStore] = useState(() => new AppealsStore());

  useEffect(() => {
    clientStore.getClient(params.id);
    appealsStore.appeals.setFilters({ filter: { client: params.id } });

    return () => clientStore.resetClient();
  }, [params.id]);

  return (
    <div className="user">
      <BreadCrumbs title={t('All accounts')} />
      <section className="user__section">
        <AccountAvatar />
        <AccountInfo />
        <AccountControls />
      </section>
      <div className="container-wrapper">
        <AccountAppeals data={appealsStore.appeals.getData} />
      </div>
      <Paginator
        totalPages={appealsStore.appeals.getTotalPages}
        currentPage={appealsStore.appeals.getCurrentPage}
        changePagination={(page) => appealsStore.appeals.loadPage(page)}
      />
      <Footer />
    </div>
  );
});

export default AccountView;
