import moment from 'moment';
import {
  LANGUAGES, PHONE_LENGTH, PHONE_HE_LENGTH, PASSPORT_LENGTH,
} from '../constants';

export const isEmail = (value) => {
  const emailRegexp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/i;
  const engDomainNameRegexp = /^[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.([a-z]{1,6}|[a-z0-9-]{1,30}\.[a-z]{2,63})$/i;

  if (value === undefined || value === null || value.length > 320 || !(emailRegexp.test(value))) {
    return false;
  }

  const emailParts = value.split('@');
  if (emailParts[0].length > 64 || emailParts[1].length > 255) {
    return false;
  }

  if (!(engDomainNameRegexp.test(emailParts[1]))) {
    return false;
  }

  return true;
};

export const isName = (value) => {
  if (typeof value !== 'undefined' && value !== null && value.length >= 1) {
    return true;
  }
  return false;
};

export const isPhone = (value, type) => {
  const phoneLength = value?.replace(/\D/g, '')?.length;
  return type === LANGUAGES.HE ? phoneLength === PHONE_HE_LENGTH : phoneLength === PHONE_LENGTH;
};

export const isPassport = (value) => value?.replace(/\D/g, '')?.length === PASSPORT_LENGTH;

export const isDate = (value) => moment(value, 'DD.MM.YYYY', true).isValid();
// TODO: add address validation
export const isAddress = (value) => Boolean(value);
