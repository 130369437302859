import React from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import Avatar from '../Avatar';
import { ReactComponent as ZoomInIcon } from '../../icons/svg/zoom_in.svg';
import { ReactComponent as ZoomOutIcon } from '../../icons/svg/zoom_out.svg';
import { ReactComponent as RotateLeftIcon } from '../../icons/svg/rotate_left.svg';
import { ReactComponent as RotateRightIcon } from '../../icons/svg/rotate_right.svg';
import { IMAGE_TYPES } from '../../constants';

import './AvatarEditor.scss';

function _AvatarEditor({
  person, isEditMode, handleEditMode, handleSubmit,
}) {
  const inputRef = React.useRef();
  const editorRef = React.useRef();
  const [image, setImage] = React.useState(null);
  const [zoom, setZoom] = React.useState(1.2);
  const [rotate, setRotate] = React.useState(0);

  const revokeBlob = () => {
    if (image) {
      URL.revokeObjectURL(image);
      setImage(null);
    }
  };

  const handleCancel = () => {
    handleEditMode(false);
    revokeBlob();
  };

  const handleClick = () => inputRef.current.click();

  const handleChange = () => {
    const { files } = inputRef.current;

    if (files && IMAGE_TYPES.includes(files?.[0].type)) {
      setImage(files[0]);
      handleEditMode(true);
      revokeBlob();
      inputRef.current.value = '';
    }
  };

  const handleUploadImage = () => {
    const avatar = editorRef.current.getImageScaledToCanvas();
    handleSubmit(avatar);
    revokeBlob();
    handleEditMode(false);
  };

  const increaseZoom = () => {
    if (zoom < 2) {
      setZoom(Math.round((zoom + 0.1) * 10) / 10);
    }
  };

  const decreaseZoom = () => {
    if (zoom > 1) {
      setZoom(Math.round((zoom - 0.1) * 10) / 10);
    }
  };

  const clockwiseRotate = () => {
    if (rotate < 315) {
      setRotate(rotate + 45);
    } else {
      setRotate(0);
    }
  };

  const anticlockwiseRotate = () => {
    if (rotate > 0) {
      setRotate(rotate - 45);
    } else {
      setRotate(315);
    }
  };

  return (
    <div className="avatar-editor">
      {isEditMode ? (
        <div className="avatar-editor__wrapper">
          <AvatarEditor
            ref={editorRef}
            image={URL.createObjectURL(image)}
            width={250}
            height={250}
            border={50}
            color={[0, 0, 0, 0.2]}
            scale={zoom}
            rotate={rotate}
            borderRadius={125}
          />
          <div className="avatar-editor__tools">
            <div className="avatar-editor__tools-row">
              <button
                type="button"
                disabled={zoom === 2}
                onClick={increaseZoom}
                className="avatar-editor__tools-button"
              >
                <ZoomInIcon />
              </button>
              <span className="avatar-editor__tools-label">Увеличение</span>
              <button
                type="button"
                disabled={zoom === 1}
                onClick={decreaseZoom}
                className="avatar-editor__tools-button"
              >
                <ZoomOutIcon />
              </button>
            </div>
            <div className="avatar-editor__tools-row">
              <button
                type="button"
                onClick={clockwiseRotate}
                className="avatar-editor__tools-button"
              >
                <RotateRightIcon />
              </button>
              <span className="avatar-editor__tools-label">Вращение</span>
              <button
                type="button"
                onClick={anticlockwiseRotate}
                className="avatar-editor__tools-button"
              >
                <RotateLeftIcon />
              </button>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="button button_primary"
              onClick={handleUploadImage}
            >
              Сохранить
            </button>
            <button
              type="button"
              className="button button_secondary"
              onClick={handleCancel}
            >
              Закрыть
            </button>
          </div>
        </div>
      ) : (
        <button type="button" className="avatar-editor__button" onClick={handleClick}>
          <Avatar person={person} size="large" />
        </button>
      )}

      <input ref={inputRef} onChange={handleChange} type="file" hidden />
    </div>
  );
}

_AvatarEditor.defaultProps = {
  person: {},
};

_AvatarEditor.propTypes = {
  person: PropTypes.shape({
    photo: PropTypes.shape({
      thumb: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  isEditMode: PropTypes.bool.isRequired,
  handleEditMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default _AvatarEditor;
