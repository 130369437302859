import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_STATUSES, ACCOUNT_ACTIONS } from '../../../../constants';
import { ReactComponent as Spinner } from '../../../../icons/svg/spinner.svg';

import clientStore from '../../../../stores/clientStore';

const AccountControls = observer(() => {
  const { t } = useTranslation();

  const handleClick = () => {
    const status = clientStore.client?.status === ACCOUNT_STATUSES.ACTIVE
      ? ACCOUNT_STATUSES.BLOCKED
      : ACCOUNT_STATUSES.ACTIVE;
    clientStore.updateClient({ status });
  };

  const isDisabled = clientStore.client?.status === ACCOUNT_STATUSES.ARCHIVED
    || clientStore.isRequestLoading;

  return (
    <div className="user__wrapper-controls">
      <button
        type="button"
        className="button button_border-less buttun_with-spinner user__button-border-less"
        disabled={isDisabled}
        onClick={() => handleClick()}
      >
        {t(clientStore.client?.status === ACCOUNT_STATUSES.ACTIVE
          ? ACCOUNT_ACTIONS.BLOCK
          : ACCOUNT_ACTIONS.ACTIVATE)}
        {clientStore.isRequestLoading && <Spinner className="spinner" width={24} height={24} />}
      </button>
    </div>
  );
});

export default AccountControls;
