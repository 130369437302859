import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from '../../components/Select';
import PasswordInput from '../../components/Inputs/PasswordInput';
import PassportInput from '../../components/PassportInput';
import DateInput from '../../components/DateInput';
import Input from '../../components/Input';
import PhoneInput from '../../components/PhoneInput';
import Loader from '../../components/UI/Loader/Loader';
import AvatarEditor from '../../components/AvatarEditor/AvatarEditor';
import { ACCOUNT_COLORED_STATUSES } from '../../constants';

import CreateAccountStore from '../../stores/views/createAccountStore';

import './CreateAdminView.scss';

const VIEWS_TITLES = {
  CREATE: 'Create an account',
  EDIT: 'Edit an account',
};

const VIEWS_SUBMIT_BUTTON_LABELS = {
  CREATE: 'Create',
  SAVE: 'Save',
  CLOSE: 'Close',
};

const statuses = Object.keys(ACCOUNT_COLORED_STATUSES).map(
  (id) => ({ id, label: ACCOUNT_COLORED_STATUSES[id].label }),
);

const CreateAdminView = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [store] = React.useState(() => new CreateAccountStore());
  const [isAvatarEditMode, setAvatarEditMode] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);

  useEffect(() => {
    store.loadUser(id);
  }, [id]);

  const handleChange = (field) => (val) => store.setValue(field, val);

  const handleSelectStatus = (status) => store.setValue('status', status.id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!store.isEdited) {
      setIsSaved(true);
      return;
    }
    const isSuccess = await store.saveUser(id);
    if (isSuccess) {
      setIsSaved(true);
    }
  };

  const getButtonLabel = () => {
    if (!store.isEdited) {
      return t(VIEWS_SUBMIT_BUTTON_LABELS.CLOSE);
    }
    if (id) {
      return t(VIEWS_SUBMIT_BUTTON_LABELS.SAVE);
    }
    return t(VIEWS_SUBMIT_BUTTON_LABELS.CREATE);
  };

  if (isSaved && !store.isRequestLoading) {
    return <Redirect to="/users" />;
  }

  return (
    <>
      <form className="create-statement" onSubmit={handleSubmit}>
        <h3 className="create-statement__title">
          {t(id ? VIEWS_TITLES.EDIT : VIEWS_TITLES.CREATE)}
        </h3>

        {id && (
          <AvatarEditor
            person={store.user}
            isEditMode={isAvatarEditMode}
            handleEditMode={setAvatarEditMode}
            handleSubmit={store.uploadImage}
          />
        )}

        {!isAvatarEditMode && (
          <>
            <div className="create-statement__controls-wrapper">
              <Select
                label={t('Status')}
                size="w100"
                value={store.formData.status ?? ''}
                valueList={statuses}
                handleChange={handleSelectStatus}
              />

              <Input
                label={t('Email')}
                value={store.formData.email ?? ''}
                error={store.isError('email')}
                onChange={handleChange('email')}
              />

              <PasswordInput
                label={t('Password')}
                value={store.formData.password ?? ''}
                error={store.isError('password')}
                onChange={handleChange('password')}
              />

              <Input
                label={t('Surname')}
                value={store.formData.lastname ?? ''}
                error={store.isError('lastname')}
                onChange={handleChange('lastname')}
              />

              <Input
                label={t('Name')}
                value={store.formData.firstname ?? ''}
                error={store.isError('firstname')}
                onChange={handleChange('firstname')}
              />

              <PhoneInput
                label={t('Phone number')}
                value={store.formData.phone}
                type={store.phoneType}
                isValid={store.isValidPhone}
                error={store.isError('phone')}
                onChange={handleChange('phone')}
                onSubmit={() => {}}
                onChangeType={(type) => store.setPhoneType(type)}
              />

              <PassportInput
                label={t('Teudat Zehut number')}
                value={store.formData.passport ?? ''}
                isValid={store.isValidPassport}
                error={store.isError('passport')}
                onChange={handleChange('passport')}
              />

              <DateInput
                label={t('Date of birth')}
                value={store.formData.birthDate ?? ''}
                isValid={store.isValidDate}
                error={store.isError('birth_date')}
                onChange={handleChange('birthDate')}
              />

              <Input
                label={t('Address')}
                value={store.formData.address ?? ''}
                error={store.isError('address')}
                onChange={handleChange('address')}
              />
            </div>

            <button
              type="submit"
              disabled={!store.isValidForm || store.isRequestLoading || isAvatarEditMode}
              className="button create-statement__button"
            >
              {getButtonLabel()}
            </button>
          </>
        )}
      </form>
      {store.isRequestLoading && <Loader />}
    </>
  );
});

export default CreateAdminView;
