import i18n from 'i18next';
import { makeAutoObservable, reaction } from 'mobx';
import {
  getToken, setToken, removeToken, getItem, setItem,
} from '../helpers/localStorageHelper';
import { DEFAULT_LANGUAGE } from '../constants';

class GlobalAppStore {
  token = null;

  lang = null;

  isOpenMenu = true;

  constructor() {
    makeAutoObservable(this);

    const localStorageToken = getToken();
    this.setToken(localStorageToken);

    const localStorageLang = getItem('lang') || DEFAULT_LANGUAGE;
    this.setLanguage(localStorageLang);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          setToken(token);
        } else {
          removeToken();
        }
      },
    );

    reaction(
      () => this.lang,
      (lang) => {
        i18n.changeLanguage(lang);
        setItem('lang', lang);
        document.body.dir = i18n.dir();
        document.body.classList.remove('rtl');
        document.body.classList.remove('ltr');
        document.body.classList.add(i18n.dir());
      },
    );
  }

  setToken(token) {
    this.token = token;
  }

  setLanguage(lang) {
    this.lang = lang;
  }

  setIsOpenMenu(isOpen) {
    this.isOpenMenu = isOpen;
  }

  toggleOpenMenu() {
    this.setIsOpenMenu(!this.isOpenMenu);
  }
}

export default new GlobalAppStore();
