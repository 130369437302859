import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import getResizedImg from '../../../../utils/getResizedImg';
import { IMAGE_TYPES, MAX_FILES_TO_COMMENT, DOCUMENT_MIME_TYPES } from '../../../../constants';
import { ReactComponent as IconPdfFile } from '../../../../icons/svg/pdf.svg';
import { ReactComponent as IconClose } from '../../../../icons/svg/close.svg';
import authStore from '../../../../stores/authStore';

import './CommentInput.scss';

const isImageFile = (file) => IMAGE_TYPES.includes(file.type);
const isDocumentFile = (file) => DOCUMENT_MIME_TYPES.includes(file.type);

const CommentInput = observer(({
  sendComment, id, showPopup, showAlert,
}) => {
  const { t } = useTranslation();

  const [commentAttachments, setCommentAttachments] = useState([]);

  const [isResizingImages, setIsResizingImages] = useState(false);

  const [value, setValue] = useState('');

  const inputRef = useRef(null);

  const clickInputFilesHandler = () => {
    if (!isResizingImages) {
      inputRef.current.click();
    }
  };

  const currentUser = authStore.user;

  const renderFiles = () => {
    if (commentAttachments.length) {
      const options = {
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        createdAt: new Date().toString(),
        avatarURL: currentUser?.photo?.thumb,
      };

      return (
        <div className="comment-input__files-wrapper">
          {commentAttachments.map((file) => {
            const { lastModifiedDate, name } = file;

            if (isDocumentFile(file)) {
              return (
                <div key={`${lastModifiedDate} ${name} ${Math.random()}`} className="comment-input__document">
                  <div className="comment-input__document-name">
                    <IconPdfFile />
                    {file.name}
                  </div>
                  <IconClose
                    className="comment-input__document-close"
                    onClick={(event) => removeAttachmentHandler(event, file)}
                  />
                </div>
              );
            }

            const imageURL = URL.createObjectURL(file);

            return (
              <div
                className="comment-input__file"
                key={`${lastModifiedDate} ${name} ${Math.random()}`}
                onClick={() => showPopup({ ...options, ...{ fileName: imageURL } }, false)}
                role="button"
                aria-hidden
              >
                <img className="comment-input__image" src={imageURL} alt="" />

                <span
                  className="comment-input__icon-remove"
                  onClick={(event) => removeAttachmentHandler(event, file, imageURL)}
                  role="button"
                  aria-hidden
                />
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const addAttachmentsHandler = () => {
    if (commentAttachments.length >= MAX_FILES_TO_COMMENT) return;

    const { files } = inputRef.current;

    Array.from(files).forEach((file) => {
      if (isImageFile(file)) {
        setIsResizingImages(true);
        getResizedImg(file)
          .then((img) => {
            setCommentAttachments((prev) => [...prev, img]);
            setIsResizingImages(false);
          })
          .catch(() => setIsResizingImages(false));
      } else if (isDocumentFile(file)) {
        setCommentAttachments((prev) => [...prev, file]);
      } else {
        showAlert([{
          text: `${files[0].name} ${t('Error type file')}`,
          id: Math.random().toString(),
        }]);
      }
    });

    inputRef.current.value = '';
  };

  const removeAttachmentHandler = (event, removedFile, imageURL) => {
    event.stopPropagation();
    setCommentAttachments((prev) => (prev.filter((file) => file !== removedFile)));
    if (imageURL) {
      URL.revokeObjectURL(imageURL);
    }
  };

  const changeCommentTextHandler = (event) => {
    setValue(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
  };

  const sendCommentHandler = async () => {
    if (value.trim() === '' || isResizingImages) return;
    setValue('');
    setCommentAttachments([]);
    await sendComment(id, value, commentAttachments);
  };

  const sendCommentBtnClasses = () => {
    const cls = ['button', 'comment-input__button '];
    if (value.trim().length === 0 || isResizingImages) {
      cls.push('button_disabled');
    }
    return cls.join(' ');
  };

  const keyPressHandler = (event) => {
    if (event.key === 'Enter') {
      sendCommentHandler();
    }
  };

  return (
    <form className="comment-input" onSubmit={submitHandler}>
      {(commentAttachments.length < MAX_FILES_TO_COMMENT)
        ? (
          <span
            className="comment-input__icon"
            onClick={clickInputFilesHandler}
            role="button"
            aria-hidden
          />
        )
        : null}

      <input type="file" hidden ref={inputRef} onChange={addAttachmentsHandler} />

      <div className="comment-input__input-wrapper">
        <input
          className="comment-input__input"
          type="text"
          placeholder={t('Write a message')}
          value={value}
          onChange={changeCommentTextHandler}
          onKeyUp={keyPressHandler}
        />

        {renderFiles()}
      </div>

      <button
        type="button"
        className={sendCommentBtnClasses()}
        onClick={sendCommentHandler}
      >
        {t('Send')}
      </button>
    </form>
  );
});

CommentInput.defaultProps = {
  id: '',
};

CommentInput.propTypes = {
  id: PropTypes.string,
  sendComment: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

export default CommentInput;
