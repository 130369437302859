import api from './IFApi';

const appealRequests = {
  async getAppeal(id) {
    return api.getAppeal(id);
  },
  async setAppealStatus(id, status) {
    return api.setAppealStatus(id, { status });
  },
  async setAppealModerator(id, responsibleId) {
    const body = {
      responsible: responsibleId,
    };
    return api.setAppealModerator(id, body);
  },
  async sendAppealComment(id, comment) {
    const body = {
      comment,
    };
    return api.sendAppealComment(id, body);
  },
  async redirectAppeal(id, directedTo) {
    return api.redirectAppeal(id, directedTo);
  },
};

export default appealRequests;
