import { makeAutoObservable, reaction } from 'mobx';

import webSocketsStore from './webSocketsStore';

class NewAppealsCountStore {
  newAppealsCount = null;

  constructor() {
    const self = this;
    makeAutoObservable(self);
    this.subscribe();

    reaction(
      () => webSocketsStore.connection,
      () => {
        this.subscribe();
      },
    );
  }

  subscribe() {
    const self = this;
    if (webSocketsStore.connection) {
      webSocketsStore.connection.emit('subscribeNewAppeals');
      webSocketsStore.connection.on('newAppealsCount', (data) => {
        self.setNewAppealsCount(data.newAppealsCount);
      });
    }
  }

  get count() {
    return this.newAppealsCount;
  }

  setNewAppealsCount(count) {
    this.newAppealsCount = count;
  }
}
export default new NewAppealsCountStore();
