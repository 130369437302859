import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import NewCommentBadge from '../../NewCommentBadge';
import History from '../../../routes/History';
import { toDateTimeReadable } from '../../../utils/DateFormatter';
import { STATUSES } from '../../../constants';

const AppealsTableLayout = ({
  appeals, isFetching, fetchFilteredAppeals, isMyAppealsTable,
}) => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortingField, setSortingField] = useState('updatedAt');
  const { t } = useTranslation();

  const toggleSort = (newSortingField) => {
    if (sortingField === newSortingField) {
      const newSortDirection = (sortDirection === 'asc') ? 'desc' : 'asc';
      setSortDirection(newSortDirection);
      fetchFilteredAppeals({
        sort: {
          [sortingField]: newSortDirection,
        },
      });
    } else {
      setSortingField(newSortingField);
      setSortDirection('desc');
      fetchFilteredAppeals({
        sort: {
          [newSortingField]: 'desc',
        },
      });
    }
  };

  const sortIcon = () => {
    const cls = ['table__icon-sort-down'];
    if (sortDirection === 'asc') {
      cls.push('table__icon-sort-up');
    }
    return (
      <span className={cls.join(' ')} />
    );
  };

  const _isOverdued = (appealCreatedAt) => moment(appealCreatedAt).isBefore(moment().subtract(2, 'days'));

  const getAppealPath = (appealId) => {
    if (isMyAppealsTable) {
      return `/my_appeals/${appealId}`;
    }
    return `/appeals/${appealId}`;
  };

  const renderTable = () => {
    if (appeals.length > 0) {
      return appeals.map((appeal) => {
        const getClassesRow = () => {
          if (appeal.status === 'new') {
            if (_isOverdued(appeal.createdAt)) {
              return 'table__row-danger';
            }
          }
          return '';
        };

        const statusCls = ['table__status-text'];
        if (appeal.status === 'new') {
          statusCls.push('table__status-text_danger');
        }
        if (appeal.status === 'finished' || appeal.status === 'done') {
          statusCls.push('table__status-text_success');
        }
        const responsibleName = appeal.responsible
          ? `${appeal.responsible.firstname} ${appeal.responsible.lastname}`
          : '';
        return (
          <tr
            key={appeal.no}
            onClick={() => History.push(getAppealPath(appeal._id))}
            className={getClassesRow()}
          >
            <td>{appeal.no}</td>
            <td>{`${appeal.client.firstname} ${appeal.client.lastname}`}</td>
            <td dir="ltr" className="table__color-gray">
              {toDateTimeReadable(appeal.createdAt)}
            </td>
            <td dir="ltr" className="table__color-gray">
              {toDateTimeReadable(appeal.updatedAt)}
            </td>
            {/* TODO: feature */}
            {/* <td>{t(categories[appeal.category])}</td> */}
            <td>
              <span className="table__contacts-wrapper">
                <span dir="ltr">{appeal.client.phone}</span>
                <span className="table__color-gray">{appeal.client.email}</span>
              </span>
            </td>
            {!isMyAppealsTable ? <td>{responsibleName}</td> : null}
            <td>
              <div className="table__cell-row">
                <span className="table__status-wrapper">
                  <span className={statusCls.join(' ')}>
                    {t(STATUSES[appeal.status])}
                  </span>
                </span>

                <NewCommentBadge appealId={appeal._id} />
              </div>
            </td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td className="table__cell-message" colSpan="7">Нет данных</td>
      </tr>
    );
  };
  return (
    <div className="statement__container-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>{t('Request ID')}</th>
            <th>{t('Name and Surname')}</th>
            <th onClick={() => toggleSort('createdAt')} className="table__cell-clickable">
              <span className="table__text-sorting">
                {t('Date of creation')}

                {sortingField === 'createdAt' ? sortIcon() : null}
              </span>
            </th>
            <th onClick={() => toggleSort('updatedAt')} className="table__cell-clickable">
              <span className="table__text-sorting">
                {t('Date of update')}

                {sortingField === 'updatedAt' ? sortIcon() : null}
              </span>
            </th>
            {/* TODO: feature */}
            {/* <th>{t('Category')}</th> */}
            <th>{`${t('Phone number')} / ${t('Email')}`}</th>
            {!isMyAppealsTable ? <th>{t('Moderator')}</th> : null}
            <th>{t('Status')}</th>
          </tr>
        </thead>
        <tbody>
          {!isFetching ? renderTable() : (
            <tr>
              <td colSpan="7" className="table__cell-message">{t('Loading data')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

AppealsTableLayout.defaultProps = {
  isMyAppealsTable: false,
};

AppealsTableLayout.propTypes = {
  appeals: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchFilteredAppeals: PropTypes.func.isRequired,
  isMyAppealsTable: PropTypes.bool,
};

export default AppealsTableLayout;
