import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { toDateTimeReadable } from '../../../utils/DateFormatter';
import AppealMap from '../AppealMap/AppealMap';
import CategorySelect from '../CategorySelect';
import Image from '../../Image';
import RedirectAppealPopup from '../RedirectAppealPopup';
import Select from '../../Select';
import { STATUSES, ACCOUNT_STATUSES, categories } from '../../../constants';

import authStore from '../../../stores/authStore';

const AppealData = observer(({
  appeal,
  responsibleId,
  users,
  changeSelect,
  changeStatusHandler,
  sendToDepartment, showPopup,
  showModal,
}) => {
  const { t } = useTranslation();
  let proceedButtonText = '';
  let proceedButtonStatus = '';
  let cancelButtonText = '';
  let cancelButtonStatus = '';
  const idCurrentUser = authStore.user?._id;
  const arrayStatusesHideButtons = ['finished', 'rejected', 'done'];

  const [isRedirectAppealPopupVisible, setIsRedirectAppealPopupVisible] = React.useState(false);

  const isVisibleCategoriesSelect = false;

  const coordinatesOfIncident = {
    lat: appeal?.location?.coordinates[0],
    lng: appeal?.location?.coordinates[1],
  };

  const getViewStatementStatusClass = (status) => {
    const statusCls = ['appeal__status'];
    if (status === 'new') {
      statusCls.push('appeal__status_danger');
    }
    if (status === 'finished' || status === 'done') {
      statusCls.push('appeal__status_success');
    }
    return statusCls.join(' ');
  };

  const viewStatementStatusClass = getViewStatementStatusClass(appeal.status);

  const getStatementDisabledSelect = () => {
    if (appeal.status === 'finished' || appeal.status === 'done') {
      return true;
    }
    return false;
  };

  if (appeal.status === 'new') {
    proceedButtonText = t('Process');
    proceedButtonStatus = 'processing';
  }

  if (appeal.status === 'processing') {
    proceedButtonText = t('Take to work');
    proceedButtonStatus = 'working';
    cancelButtonText = t('Reject');
    cancelButtonStatus = 'rejected';
  }

  if (appeal.status === 'working') {
    proceedButtonText = t('Performed');
    proceedButtonStatus = 'done';
    cancelButtonText = t('To finish');
    cancelButtonStatus = 'finished';
  }

  const options = {
    firstname: appeal.client?.firstname,
    lastname: appeal.client?.lastname,
    createdAt: appeal.createdAt,
    avatarURL: appeal.client?.photo?.thumb ?? '',
    isRevokeObjectURL: true,
  };

  const proceedButtonHandler = () => {
    if (proceedButtonStatus !== 'done') {
      changeStatusHandler(proceedButtonStatus);
    } else {
      showModal(proceedButtonStatus);
    }
  };

  const cancelButtonHandler = () => {
    if (cancelButtonStatus !== 'finished' && cancelButtonStatus !== 'rejected') {
      changeStatusHandler(cancelButtonStatus);
    } else {
      showModal(cancelButtonStatus);
    }
  };

  const normalizeUsers = (items) => items
    .filter((u) => u.status === ACCOUNT_STATUSES.ACTIVE)
    .map((u) => ({ id: u._id, label: `${u.firstname} ${u.lastname}` }));

  const getSendedStatusText = () => {
    if (!appeal.directed_to) {
      if (appeal.status === 'processing' || appeal.status === 'working') {
        return (
          <button
            className="button button_gray appeal__button  appeal__button_send"
            onClick={() => setIsRedirectAppealPopupVisible(true)}
            type="button"
          >
            Направить в ведомство
          </button>
        );
      }

      return null;
    }

    if (appeal.directed_to.department) {
      return (
        <span className="appeal__sent-department">
          Направлено в ведомство
          {' '}
          {t('left quotation mark')}
          {t(categories[appeal.directed_to.department])}
          {t('right quotation mark')}
        </span>
      );
    }

    return (
      <span className="appeal__sent-department">
        Направлено по адресу&nbsp;
        <span className="appeal__sent-department_email">
          {appeal.directed_to.email}
        </span>
      </span>
    );
  };

  return (
    <>
      <div className="appeal__left-column">
        <span className={viewStatementStatusClass}>
          {t(STATUSES[appeal.status])}
        </span>

        <div className="appeal__title-wrapper">
          <span className="appeal__title">
            <span>{`${t('Request')} ${appeal.no}`}</span>
            <span className="appeal__date-title">
              {t('at')}
              {toDateTimeReadable(appeal.createdAt, true)}
            </span>
          </span>

          {getSendedStatusText()}
        </div>
        {isVisibleCategoriesSelect ? (
          <CategorySelect
            appeal={appeal}
            isDisabled={getStatementDisabledSelect()}
          />
        ) : null}

        <Select
          label={t('Assign a moderator')}
          value={responsibleId}
          valueList={normalizeUsers(users)}
          handleChange={changeSelect}
          isDisabled={getStatementDisabledSelect()}
        />

        <div className="appeal__list">
          <span className="appeal__list_title">{`${t('Applicant')}: `}</span>

          {appeal.client
            ? `${appeal.client.firstname} ${appeal.client.lastname}`
            : ''}
        </div>

        <div className="appeal__list">
          <span className="appeal__list_title">{`${t('Contacts')}: `}</span>
          {appeal.client ? (
            <span dir="ltr">{`${appeal.client.phone}; ${appeal.client.email}`}</span>
          ) : (
            ''
          )}
        </div>

        <div className="appeal__list">
          <span className="appeal__list_title">{`${t('Address')}: `}</span>

          <span className="appeal__list_underline">{appeal.address}</span>
        </div>

        <div className="appeal__text">
          {appeal.description ? appeal.description : ''}
        </div>

        {appeal.photos?.length ? (
          <div className="appeal__image-wrapper">
            {appeal.photos.map((photo) => (
              <Image
                key={photo.thumb}
                thumb={photo.thumb}
                bigImageUrl={photo.url}
                options={options}
                showPopup={showPopup}
                imageClass="appeal__image"
                initialImageClass="appeal__initial-image"
              />
            ))}
          </div>
        ) : null}
      </div>

      <div className="appeal__right-column">
        {appeal.status
        && !arrayStatusesHideButtons.includes(appeal.status)
        && responsibleId === idCurrentUser ? (
          <div className="appeal__button-wrapper">
            <button
              type="button"
              className="button appeal__button"
              onClick={proceedButtonHandler}
            >
              {proceedButtonText}
            </button>
            {appeal.status !== 'new' ? (
              <button
                type="button"
                className="button button_gray appeal__button"
                onClick={cancelButtonHandler}
              >
                {cancelButtonText}
              </button>
            ) : null}
          </div>
          ) : (
            <div className="appeal__button-wrapper-empty" />
          )}

        <AppealMap coordinatesOfIncident={coordinatesOfIncident} />
      </div>
      <RedirectAppealPopup
        closePopup={() => setIsRedirectAppealPopupVisible(false)}
        popupVisible={isRedirectAppealPopupVisible}
        sendToDepartment={sendToDepartment}
      />
    </>
  );
});

AppealData.defaultProps = {
  appeal: {
    status: '',
    description: '',
    address: '',
    no: '',
    createdAt: '',
    category: '',
    photos: [],
    client: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      createdAt: '',
      photo: {
        thumb: '',
      },
    },
    location: {
      coordinates: [31.6816569, 34.55717870000001],
    },
  },
};

AppealData.propTypes = {
  appeal: PropTypes.shape({
    status: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    no: PropTypes.string,
    createdAt: PropTypes.string,
    category: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.object),
    client: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      createdAt: PropTypes.string,
      photo: PropTypes.objectOf(PropTypes.string),
    }),
    location: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  responsibleId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeSelect: PropTypes.func.isRequired,
  changeStatusHandler: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  sendToDepartment: PropTypes.func.isRequired,
};

export default AppealData;
