import React from 'react';
import Routes from '../../routes/Routes';

import './App.scss';

function App() {
  return <Routes />;
}

export default App;
