import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_COLORED_STATUSES } from '../../constants';

import './AccountStatus.scss';

const getLabel = (status) => ACCOUNT_COLORED_STATUSES[status]?.label;

const getColor = (status) => ACCOUNT_COLORED_STATUSES[status]?.color;

function AccountStatus({ status }) {
  const { t } = useTranslation();

  return (
    <span className={`
      account-status
      account-status_${getColor(status)}
      account-status_${i18next.dir()}`}
    >
      {t(getLabel(status))}
    </span>
  );
}

AccountStatus.defaultProps = {
  status: '',
};

AccountStatus.propTypes = {
  status: PropTypes.string,
};

export default AccountStatus;
