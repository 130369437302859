import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import globalAppStore from '../../../stores/globalAppStore';

const Logo = observer(() => {
  const { t } = useTranslation();
  const cls = ['aside__header'];
  if (!globalAppStore.isOpenMenu) {
    cls.push('aside__header_menu-hidden');
  }

  return (
    <div className={cls.join(' ')}>
      <NavLink to="/" className="aside__header-image">
        <img src="/images/aside.png" alt="header" />
      </NavLink>

      {globalAppStore.isOpenMenu ? (
        <>
          <div className="aside__title">{t('ATID')}</div>

          <div className="aside__sub-title">{t('Let\'s build our future together')}</div>
        </>
      ) : null}

    </div>
  );
});

export default Logo;
