import { makeAutoObservable } from 'mobx';
import DataStore from './dataStore';
import { doPatch } from '../requests/if_api/IFApi';
import { RESPONSE_STATUS_CODES } from '../constants';

const url = 'appeals';

class AppealsStore {
  appeals = new DataStore({
    url,
    expand: ['client', 'responsible'],
    pagingProps: {
      limit: 5,
    },
  });

  appeal = null;

  isRequestLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setRequestLoading(loading) {
    this.isRequestLoading = loading;
  }

  setAppeal(appeal) {
    this.appeal = appeal;
  }

  async patchAppeal(id, options) {
    this.setRequestLoading(true);
    const result = await doPatch(`${url}/${id}`, options);
    if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
      this.setAppeal(result.data);
    }
    this.setRequestLoading(false);
  }
}

export default AppealsStore;
