import React from 'react';
import classNames from 'classnames';

import './BreadCrumbs.scss';

import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

function BreadCrumbs({ title, className }) {
  const history = useHistory();

  return (
    <div className={classNames('breadcrumbs', className)}>
      <button
        type="button"
        className="breadcrumbs_link"
        onClick={() => history.goBack()}
      >
        <span className="breadcrumbs_icon-back" />
        <span className="breadcrumbs_title">{title}</span>
      </button>
    </div>
  );
}

BreadCrumbs.defaultProps = {
  className: '',
};

BreadCrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BreadCrumbs;
