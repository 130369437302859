import { makeAutoObservable } from 'mobx';
import getImageBlobUrl from '../requests/if_api/ImageBlob';

class ImageDataStore {
  images = {};

  constructor() {
    makeAutoObservable(this);
  }

  setImage(url, image) {
    this.images[url] = image;
  }

  * getImage(url) {
    if (this.images[url] !== undefined) {
      return this.images[url];
    }

    this.setImage(url, '');
    const image = yield loadImage(url);
    this.setImage(url, image);

    return image;
  }
}

async function loadImage(url) {
  const blobUrl = await getImageBlobUrl(url);
  return blobUrl;
}

export default new ImageDataStore();
