import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import './Tooltip.scss';

const Tooltip = observer(({ title, children }) => {
  const tooltipRef = React.useRef();
  const [offset, setOffset] = React.useState({});

  const handleTooltipHover = () => {
    const { left, width, top } = tooltipRef.current.getBoundingClientRect();
    setOffset({
      left: `${left + width / 2}px`,
      top: `${top - 10}px`,
    });
  };

  return (
    <div className="tooltip" ref={tooltipRef} onMouseEnter={handleTooltipHover}>
      <div className="tooltip__title" style={offset}>
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
});

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Tooltip;
