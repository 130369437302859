import { makeAutoObservable } from 'mobx';
import DataStore from './dataStore';

class UserStore {
  users = new DataStore({
    url: 'users',
    pagingProps: {
      limit: 5,
    },
  });

  clients = new DataStore({
    url: 'clients',
  });

  constructor() {
    makeAutoObservable(this);
  }
}

export default new UserStore();
