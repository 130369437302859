import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './RadioButton.scss';

function RadioButton({
  idx,
  isChecked,
  isDisabled,
  label,
  name,
  onChange,
  value,
}) {
  const id = `radio-button_${idx}`;

  return (
    <div
      className={classNames(
        'radio-button',
        isDisabled && 'radio-button_disabled',
      )}
    >
      <input
        checked={isChecked}
        className="radio-button__input"
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={() => onChange(value)}
        type="radio"
        value={value}
      />
      <label htmlFor={id} className="radio-button__label">
        <div className="radio-button__icon" />
        {label}
      </label>
    </div>
  );
}

RadioButton.defaultProps = {
  isDisabled: false,
};

RadioButton.propTypes = {
  idx: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default RadioButton;
