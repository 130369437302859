import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Badge from './Badge';

function MenuItem({
  path, name, icon, counter, isActive, menuVisible,
}) {
  const { t } = useTranslation();
  const activeClass = 'aside__list-item_active';

  const iconClass = icon ? `aside__icon aside__icon_${icon}` : 'aside__icon';

  const cls = ['aside__list-item-link'];
  if (!menuVisible) {
    cls.push('aside__list-item-link__menu-hidden');
  }

  return (
    <li className={`aside__list-item ${isActive ? activeClass : ''}`}>
      <NavLink exact to={path || '/'} className={cls.join(' ')}>
        <span className={iconClass} />
        {menuVisible ? (
          <span className="aside__item-messages">
            {name ? t(name) : ''}
            {counter ? <Badge counter={counter} /> : ''}
          </span>
        ) : null}
      </NavLink>
    </li>
  );
}

MenuItem.defaultProps = {
  counter: null,
  isActive: false,
  menuVisible: false,
};

MenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  counter: PropTypes.shape({ count: PropTypes.number }),
  isActive: PropTypes.bool,
  menuVisible: PropTypes.bool,
};

export default MenuItem;
