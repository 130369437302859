import React from 'react';
import { useTranslation } from 'react-i18next';

import './SearchInput.scss';

function SearchInput() {
  const { t } = useTranslation();

  return (
    <div className="search">
      <span className="filters__icon-search" />
      <input
        type="text"
        placeholder={t('Search')}
        className="filters__input"
        // value={filters.search}
        // onChange={handlerInputChange}
        // onKeyUp={keyPressHandler}
      />
    </div>
  );
}

export default SearchInput;
