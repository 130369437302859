/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { doUrlRequest } from '../../requests/if_api/IFApi';
import Loader from '../UI/Loader/Loader';
import { ReactComponent as IconClose } from '../../icons/svg/close.svg';
import { ReactComponent as IconLeft } from '../../icons/svg/left.svg';
import { ReactComponent as IconRight } from '../../icons/svg/right.svg';
import { ReactComponent as IconOpenPdf } from '../../icons/svg/open-pdf.svg';
import { RESPONSE_STATUS_CODES } from '../../constants';
import './styles.scss';

// to render *.pdf in cra-project
// https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const contentType = 'application/pdf';
const options = {
  headers: { 'Content-Type': contentType },
  responseType: 'arraybuffer',
};

function PDFViewer({ url, handleClose }) {
  const documentRef = useRef(null);
  const [file, setFile] = useState(null);
  const [objUrl, setObjUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    function handleClickOutside(e) {
      if (documentRef.current && !documentRef.current.contains(e.target)) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [documentRef]);

  useEffect(() => {
    const getPdf = async () => {
      const result = await doUrlRequest(url, options);
      if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
        setFile(result.data);
        setObjUrl(URL.createObjectURL(new Blob([Buffer.from(result.data)], { type: contentType })));
      }
    };

    if (url) {
      getPdf();
    }

    return () => URL.revokeObjectURL(objUrl);
  }, [url]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (!file) {
    return <Loader />;
  }

  return (
    <div className="react-pdf__Wrapper">
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        inputRef={documentRef}
        loading=""
      >
        <Page pageNumber={pageNumber} />
        <IconClose width={32} height={32} className="react-pdf__Close" onClick={handleClose} />
        <div className="react-pdf__Pager">
          <div className="react-pdf__Pager-controls">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className="react-pdf__Pager-button"
            >
              <IconLeft />
            </button>
            <div className="react-pdf__Pager-info">{`${pageNumber} of ${numPages}`}</div>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className="react-pdf__Pager-button"
            >
              <IconRight />
            </button>
          </div>
          <a
            href={objUrl}
            target="_blank"
            rel="noreferrer"
            className="react-pdf__Pager-link"
          >
            <IconOpenPdf />
          </a>
        </div>
      </Document>
    </div>
  );
}

export default PDFViewer;

PDFViewer.defaultProps = {
  url: '',
};

PDFViewer.propTypes = {
  url: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};
