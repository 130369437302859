import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import AccountStatus from '../../../../components/AccountStatus/AccountStatus';
import { ReactComponent as EditIcon } from '../../../../icons/svg/edit.svg';
import formatPhoneNumber from '../../../../utils/formatPhoneNumber';

import clientStore from '../../../../stores/clientStore';
import authStore from '../../../../stores/authStore';

const DETAILS_FIELDS = [
  { valueName: 'birth_date', label: 'Date of birth', type: 'data' },
  { valueName: 'createdAt', label: 'Date of registration', type: 'data' },
  { valueName: 'phone', label: 'Phone number', type: 'phone' },
  { valueName: 'email', label: 'Email', type: 'text' },
  { valueName: 'address', label: 'Address', type: 'text' },
];

const getClient = () => clientStore.client || {};

const renderValue = (row) => {
  switch (row.type) {
    case 'data':
      return moment(getClient()[row.valueName]).format('DD.MM.YYYY');
    case 'phone':
      return <span dir="ltr">{formatPhoneNumber(getClient()[row.valueName])}</span>;
    default:
      return getClient()[row.valueName];
  }
};

const AccountInfo = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleEditClick = (e) => {
    e.preventDefault();

    if (authStore.user.role === 'admin' && clientStore.client._id) {
      history.push(`/edit_user/${clientStore.client._id}`);
    }
  };

  return (
    <div className="user__info">
      <h3 className="user__name">
        {`${getClient().firstname ?? ''} ${getClient().lastname ?? ''}`}
        <span className="user__status">
          <AccountStatus status={getClient().status} />
        </span>
        <EditIcon className="user__icon-edit" onClick={handleEditClick} />
      </h3>
      <div className="user__data-wrapper">
        {DETAILS_FIELDS.map((row) => (
          <div key={row.valueName} className="user__data-item">
            {t(row.label)}
            {': '}
            <span className="user__data-item_dark">{renderValue(row)}</span>
          </div>
        ))}
      </div>
    </div>
  );
});

export default AccountInfo;
