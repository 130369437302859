import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckedCheckboxIcon } from '../../icons/svg/checkbox-checked.svg';
import { ReactComponent as UncheckedCheckboxIcon } from '../../icons/svg/checkbox-unchecked.svg';
import compareArrays from '../../utils/compareArrays';

import './MultiSelect.scss';

function MultiSelect({
  label,
  values,
  options,
  SELECT_ALL_KEY,
  SELECT_ALL_LABEL,
  isDisabled,
  onSelect,
  currentSelected,
}) {
  const { t } = useTranslation();
  const selectRef = useRef(null);
  const optionsRef = useRef(Object.keys(options));
  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const [selected, setSelectedValues] = useState(values);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(e) {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setSelectedValues(values);
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, selectRef]);

  useEffect(() => {
    if (isSelectedAll && (selected.length !== optionsRef.current.length)) {
      setSelectedValues(optionsRef.current);
    } else if (!isSelectedAll && selected.length === optionsRef.current.length) {
      setSelectedValues([]);
    }
  }, [isSelectedAll]);

  useEffect(() => {
    if (!isSelectedAll && (selected.length === optionsRef.current.length)) {
      setIsSelectedAll(true);
    } else if (isSelectedAll && (selected.length !== optionsRef.current.length)) {
      setIsSelectedAll(false);
    }
  }, [selected]);

  useEffect(() => {
    if (Array.isArray(currentSelected)) {
      setSelectedValues(currentSelected);
    }
  }, [currentSelected]);

  const isCheckedOption = (option) => selected.includes(option);

  const handleOpen = () => {
    if (isOpen) {
      setSelectedValues(values);
    }
    setIsOpen((val) => !val);
  };

  const handleSelect = (option) => {
    if (option === SELECT_ALL_KEY) {
      setIsSelectedAll((val) => !val);
    } else if (isCheckedOption(option)) {
      setSelectedValues(selected.filter((s) => s !== option));
    } else {
      setSelectedValues([option, ...selected]);
    }
  };

  const handleSubmit = () => {
    setIsOpen(false);

    if (isSelectedAll && (values.length === optionsRef.current.length)) {
      return;
    }

    if (isSelectedAll && (values.length !== optionsRef.current.length)) {
      onSelect(optionsRef.current);
      return;
    }

    if (compareArrays(values, selected)) {
      return;
    }

    if (!selected.length) {
      setIsSelectedAll(true);
      onSelect(optionsRef.current);
      return;
    }

    onSelect(selected);
  };

  const renderSelectedValues = () => (
    <div className="multi-select__selected-items">
      {isSelectedAll ? (
        <div className="multi-select__selected-item">{SELECT_ALL_LABEL}</div>
      ) : (
        selected.map((key) => (
          <div key={key} className="multi-select__selected-item">
            {t(options[key])}
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="multi-select__wrapper" ref={selectRef}>
      <button
        type="button"
        disabled={isDisabled}
        onClick={handleOpen}
        className={classNames(
          'multi-select',
          isOpen && 'is-open',
          label && 'multi-select_labeled',
          selected?.length && 'not-empty',
          i18next.dir(),
        )}
      >
        {label && <span className="multi-select__label">{label}</span>}
        {optionsRef.current.length > 0 ? renderSelectedValues() : null}
      </button>

      {isOpen && (
        <div className="multi-select__dropbox">
          <ul className="list scrollbar">
            {SELECT_ALL_KEY
              && SELECT_ALL_LABEL
              && optionsRef.current.length > 0 ? (
                <li className="list__item-wrapper">
                  <button
                    type="button"
                    className="list__item"
                    onClick={() => handleSelect(SELECT_ALL_KEY)}
                  >
                    {isSelectedAll ? (
                      <CheckedCheckboxIcon />
                    ) : (
                      <UncheckedCheckboxIcon />
                    )}
                    {SELECT_ALL_LABEL}
                  </button>
                </li>
              ) : null}
            {optionsRef.current.map((key) => (
              <li className="list__item-wrapper" key={key}>
                <button
                  type="button"
                  className="list__item"
                  onClick={() => handleSelect(key)}
                >
                  {isCheckedOption(key) ? (
                    <CheckedCheckboxIcon />
                  ) : (
                    <UncheckedCheckboxIcon />
                  )}
                  {t(options[key])}
                </button>
              </li>
            ))}
            {!optionsRef.current.length ? (
              <li className="list__item-wrapper">
                <button type="button" className="list__item is-empty" disabled>
                  пусто
                </button>
              </li>
            ) : null}
          </ul>
          {optionsRef.current.length ? (
            <button
              type="button"
              className="multi-select__button-submit"
              onClick={handleSubmit}
            >
              {t('OK')}
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
}

MultiSelect.defaultProps = {
  label: null,
  values: [],
  options: {},
  SELECT_ALL_KEY: null,
  SELECT_ALL_LABEL: null,
  isDisabled: false,
  currentSelected: null,
};

MultiSelect.propTypes = {
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.objectOf(PropTypes.any),
  SELECT_ALL_KEY: PropTypes.string,
  SELECT_ALL_LABEL: PropTypes.string,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  currentSelected: PropTypes.arrayOf(PropTypes.string),
};

export default MultiSelect;
