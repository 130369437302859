/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Avatar from '../Avatar';

import { LoginRoute } from '../../routes/Constants';
import { LANGUAGES } from '../../constants';

import globalAppStore from '../../stores/globalAppStore';
import authStore from '../../stores/authStore';

const renderLangIcon = (lang) => {
  switch (lang) {
    case LANGUAGES.RU:
      return <span className="header__icon-flag header__icon-flag_rus" />;
    case LANGUAGES.EN:
      return <span className="header__icon-flag header__icon-flag_en" />;
    case LANGUAGES.HE:
      return <span className="header__icon-flag header__icon-flag_isr" />;
    default:
      return <span className="header__icon-flag header__icon-flag_rus" />;
  }
};

const Header = observer(({ title }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeLang = (lang) => globalAppStore.setLanguage(lang);

  function logOut(e) {
    e.preventDefault();
    authStore.signOut();
    history.push(LoginRoute);
  }

  return (
    <div className="header">
      <div className="header__category-name">{t(title)}</div>

      <div className="header__right-column">
        <div className="header__user-data">
          <span className="header__user-name">
            {`${authStore.user?.firstname ?? ''} ${authStore.user?.lastname ?? ''}`}
          </span>

          <Link to={`/edit_admin/${authStore.user?._id}`} className="header__profile-circle">
            <Avatar person={authStore.user} />
          </Link>
        </div>

        <div className="header__language-menu-wrapper">
          <div className="header__language">
            {renderLangIcon(globalAppStore.lang)}

            <span className="header__icon-angle-down" />
          </div>

          <ul className="header__language-menu">
            <li className="header__language-menu-item" data-lang="rus">
              <button
                onClick={() => handleChangeLang(LANGUAGES.RU)}
                type="button"
                className="header__language-menu-link"
              >
                <span className="header__icon-flag header__icon-flag_rus" />
              </button>
            </li>

            <li className="header__language-menu-item" data-lang="en">
              <button
                onClick={() => handleChangeLang(LANGUAGES.EN)}
                type="button"
                className="header__language-menu-link"
              >
                <span className="header__icon-flag header__icon-flag_en" />
              </button>
            </li>

            <li className="header__language-menu-item" data-lang="isr">
              <button
                onClick={() => handleChangeLang(LANGUAGES.HE)}
                type="button"
                className="header__language-menu-link"
              >
                <span className="header__icon-flag header__icon-flag_isr" />
              </button>
            </li>
          </ul>
        </div>

        <a href="/" className="header__log-out" onClick={logOut}>
          <span className="header__icon-log-out" />
        </a>
      </div>
    </div>
  );
});

export default Header;
