import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as IconEye } from '../../../icons/svg/fa-eye.svg';
import { ReactComponent as IconEyeClose } from '../../../icons/svg/fa-eye-close.svg';
import { KEYCODE_ENTER } from '../../../constants';

const INPUT_TYPES = {
  PASSWORD: 'password',
  TEXT: 'rext',
};

function PasswordInput({
  value,
  onChange,
  label,
  error,
  isDisabled,
  className,
}) {
  const [type, setType] = useState(INPUT_TYPES.PASSWORD);
  const [isFocus, setIsFocus] = useState(false);

  const handleKeyUp = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      setIsFocus(false);
    }
  };

  const handleOnFocus = () => setIsFocus(true);

  const handleOnBlur = () => setIsFocus(false);

  return (
    <div
      className={classNames(
        'input-field',
        label && 'input-field_labeled',
        isFocus && 'input-field_focused',
        value && 'not-empty',
        !isFocus && error && value && 'invalid',
      )}
    >
      {label && <span className="input-field__label">{label}</span>}
      <input
        autoComplete="off"
        value={value}
        onChange={onChange}
        type={type}
        disabled={isDisabled}
        onKeyUp={handleKeyUp}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        className={classNames('input-field__input', className)}
      />
      <div className="login-in__password-icon">
        {type === INPUT_TYPES.TEXT ? (
          <IconEye onClick={() => setType(INPUT_TYPES.PASSWORD)} />
        ) : (
          <IconEyeClose onClick={() => setType(INPUT_TYPES.TEXT)} />
        )}
      </div>
    </div>
  );
}

PasswordInput.defaultProps = {
  label: '',
  error: false,
  isDisabled: false,
  className: '',
};

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default PasswordInput;
