import React from 'react';
import PropTypes from 'prop-types';

import './Comment.scss';

import Image from '../../../Image';
import Avatar from '../../../Avatar';
import { ReactComponent as IconPdfFile } from '../../../../icons/svg/pdf.svg';

import getFormattedTime from '../../../../utils/getFormattedTime';

const getAttachmentName = (attachment, idx) => {
  if (attachment?.name) {
    return attachment?.name;
  }

  const ext = attachment?.url?.split('.').slice(-1)?.[0];

  return ext ? `${idx + 1}.${ext}` : '';
};

const Comment = ({
  comment, showPopup, showPDF,
}) => {
  const {
    text, createdAt, author, attachments,
  } = comment;

  const date = new Date(createdAt);

  const options = {
    firstname: author.firstname,
    lastname: author.lastname,
    createdAt,
    avatarURL: author?.photo?.thumb ?? '',
    isRevokeObjectURL: true,
  };

  const renderCommentImages = (commentAttachments, imageOptions) => {
    if (commentAttachments?.length > 0) {
      return (
        <div className="comment__files-wrapper">
          {commentAttachments.map((attachment, idx) => {
            if (attachment.thumb) {
              return (
                <div
                  className="comment__file"
                  key={attachment.thumb}
                  onClick={() => showPopup({ ...options, ...{ fileName: attachment.url } })}
                  aria-hidden
                >
                  <Image
                    thumb={attachment.thumb}
                    bigImageUrl={attachment.url}
                    options={imageOptions}
                    showPopup={showPopup}
                    imageClass="comment__image"
                    initialImageClass="comment__initial-image"
                  />
                  <span className="comment__file-name">{getAttachmentName(attachment, idx)}</span>
                </div>
              );
            }

            return (
              <div
                key={attachment.url}
                className="comment__file"
                onClick={() => showPDF(attachment.url)}
                aria-hidden
              >
                <IconPdfFile />
                <span className="comment__file-name">{getAttachmentName(attachment, idx)}</span>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="comment">
      <Avatar person={author} />

      <div className="comment__wrapper">
        <div className="comment__message">
          <div className="comment__message-title">
            <span className="comment__user-name">{`${author.firstname} ${author.lastname}`}</span>

            <span className="comment__message-time">
              &nbsp;
              {`${getFormattedTime(date)}`}
            </span>
          </div>

          <div className="comment__message-text">
            {text}
          </div>
        </div>
        {renderCommentImages(attachments, options)}
      </div>
    </div>
  );
};

Comment.defaultProps = {
  comment: {},
};

Comment.propTypes = {
  comment: PropTypes.objectOf(PropTypes.any),
  showPopup: PropTypes.func.isRequired,
  showPDF: PropTypes.func.isRequired,
};

export default Comment;
