import {
  action, computed, flow, makeObservable, observable,
} from 'mobx';
import moment from 'moment';
import { doPatch, doRequest, doUploadFiles } from '../../requests/if_api/IFApi';
import { LANGUAGES, RESPONSE_STATUS_CODES, PHONE_CODE_RU } from '../../constants';

import globalAppStore from '../globalAppStore';
import BaseFormStore from '../commonComponents/baseFormStore';

const url = 'clients';

const CLIENT_FIELDS = ['phone', 'firstname', 'lastname', 'email', 'address', 'passport', 'birthDate'];

const IMAGE_QUALITY = 0.8;
const IMAGE_DEFAULT_MIME_TYPE = 'image/jpeg';

class EditUserForm extends BaseFormStore {
  requiredFields = CLIENT_FIELDS;

  phoneType = LANGUAGES.HE;

  formData = {};

  client = {};

  isRequestLoading = false;

  errors = [];

  constructor(props) {
    super(props);
    makeObservable(this, {
      phoneType: observable,
      formData: observable,
      client: observable,
      isRequestLoading: observable,
      errors: observable,
      setClient: action,
      setErrors: action,
      setPhoneType: action,
      getFormData: computed,
      getClient: flow,
      saveClient: flow,
    });

    this.uploadImage = this.uploadImage.bind(this);
  }

  isError(field) {
    if (field === 'birth_date') {
      return this.errors?.includes(field) || !this.checkField('birthDate');
    }
    return this.errors?.includes(field) || !this.checkField(field);
  }

  get getFormData() {
    return this.formData;
  }

  setClient(client) {
    this.client = client;
  }

  setErrors(errors) {
    this.errors = errors;
  }

  setPhoneType(phoneType, isClearPhone = true) {
    this.phoneType = phoneType;
    if (isClearPhone) {
      this.setValue('phone', '');
    }
  }

  setRequestLoading(loading) {
    this.isRequestLoading = loading;
  }

  * getClient(id) {
    this.setRequestLoading(true);

    try {
      const result = yield doRequest(`${url}/${id}`);

      if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
        this.setClient(result.data);
        this.requiredFields.forEach((field) => {
          if (field === 'phone' && this.client[field][1] === PHONE_CODE_RU) {
            this.setPhoneType(LANGUAGES.RU, false);
          }
          if (field === 'birthDate') {
            this.setValue(field, moment(this.client.birth_date).format('DD.MM.YYYY'));
          } else {
            this.setValue(field, this.client[field]);
          }
        });
        this.setIsEdited(false);
      }
    } catch (error) {
      if (error?.response?.status === RESPONSE_STATUS_CODES.BAD_DATA) {
        let errors = error?.response?.data?.errors;
        if (errors) {
          errors = errors.replace(/Incorrect /gi, '').split(', ');
          this.setErrors(errors);
        }
      }
    }
    this.setRequestLoading(false);
  }

  * saveClient() {
    this.setRequestLoading(true);

    this.setErrors(null);

    const options = {
      ...this.formData,
      phone: `+${this.formData.phone}`,
      locale: globalAppStore.lang,
      birthDate: moment(this.formData.birthDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    };

    try {
      if (this.client._id) {
        const updatedOptions = this.getUpdatedOptions(this.client, options);
        if (Object.keys(updatedOptions).length) {
          const result = yield doPatch(`${url}/${this.client._id}`, updatedOptions);

          this.setRequestLoading(false);

          return result.status === RESPONSE_STATUS_CODES.SUCCESS;
        }
      }
    } catch (error) {
      if (error?.response?.status === RESPONSE_STATUS_CODES.BAD_DATA) {
        let errors = error?.response?.data?.errors;
        if (errors) {
          errors = errors.replace(/Incorrect /gi, '').split(', ');
          this.setErrors(errors);
        }
      }
    }

    this.setRequestLoading(false);
    return false;
  }

  uploadImage(image) {
    if (image && this.client._id) {
      image.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'image.jpeg');
        const headers = { 'Content-Type': 'multipart/form-data' };
        const result = await doUploadFiles(`${url}/${this.client._id}/images`, formData, { headers });

        if (result.status === RESPONSE_STATUS_CODES.SUCCESS) {
          this.setClient({
            ...this.client,
            photo: result.data.photo,
          });
        }
      }, IMAGE_DEFAULT_MIME_TYPE, IMAGE_QUALITY);
    }
  }
}

export default EditUserForm;
