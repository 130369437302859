import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruTranslation from '../locales/ru.json';
import enTranslation from '../locales/en.json';
import heTranslation from '../locales/he.json';

import globalAppStore from '../stores/globalAppStore';

i18n.use(initReactI18next).init({
  lng: globalAppStore.lang,
  fallbackLng: 'en',
  supportedLngs: ['ru', 'en', 'he'],
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ru: {
      translation: ruTranslation,
    },
    en: {
      translation: enTranslation,
    },
    he: {
      translation: heTranslation,
    },
  },
}).then(() => {
  const dir = i18n.dir();
  document.body.classList.add(dir);
  document.body.dir = dir;
});
