import React from 'react';

import './AccountsTable.scss';

import { Observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabsStore from '../../stores/tabsStore';
import userStore from '../../stores/userStore';
import clientStore from '../../stores/clientStore';
import ControlTabs from './components/ControlTabs';
import SearchInput from './components/SearchInput';
import Tables from './components/Tables/Tables';
import Footer from '../../components/UI/Footer/Footer';
import Paginator from '../../components/UI/Paginator';

const TABS = [
  {
    id: 1,
    label: 'Users',
    value: 'clients',
    store: clientStore.clients,
    addLink: '/create_user',
    addLinkLabel: 'New user',
    disabled: true,
  },
  {
    id: 2,
    label: 'Administrators',
    value: 'users',
    store: userStore.users,
    filters: { role: 'admin' },
    addLink: 'create_admin',
    addLinkLabel: 'New account',
  },
];

const tabsStore = new TabsStore(TABS);

const getCurrentStore = () => tabsStore.getCurrentTab.store;

function AccountsTable() {
  const { t } = useTranslation();

  const renderLink = () => {
    const { label, url, disabled } = tabsStore.getAddLink;
    if (disabled) {
      return '';
    }

    return (
      <Link to={url} disabled={disabled} className="base-accounts__add-link">
        {t(label)}
      </Link>
    );
  };

  return (
    <div className="base-accounts">
      <div className="base-accounts__controls-wrapper">
        <nav className="base-accounts__tabber">
          <ControlTabs store={tabsStore} t={t} />
        </nav>
        <div className="base-accounts__search">
          <SearchInput />
        </div>
      </div>
      <div className="container-wrapper base-accounts__table-wrapper">
        <Tables store={tabsStore} />
      </div>
      <div className="statement__footer">
        <Observer>
          {() => (
            <>
              {renderLink()}
              <Paginator
                totalPages={getCurrentStore().getTotalPages}
                currentPage={getCurrentStore().getCurrentPage}
                changePagination={(page) => getCurrentStore().loadPage(page)}
              />
            </>
          )}
        </Observer>
      </div>
      <Footer />
    </div>
  );
}

export default AccountsTable;
