import React from 'react';
import { observer } from 'mobx-react-lite';
import Filters from '../../components/AppealsTable/Filters/Filters';
import AppealsTableLayout from '../../components/AppealsTable/AppealsTableLayout/AppealsTableLayout';
import Loader from '../../components/UI/Loader/Loader';
import Footer from '../../components/UI/Footer/Footer';
import Paginator from '../../components/UI/Paginator';

import authStore from '../../stores/authStore';
import AppealsStore from '../../stores/appealStore';
import newAppealsCountStore from '../../stores/webSockets/newAppealsCountStore';

const DEFAULT_SORT = { updatedAt: 'desc' };

const AppealsView = observer(({ isUserAppeals }) => {
  const [store] = React.useState(() => new AppealsStore());

  const userAppealsFilters = isUserAppeals ? { responsible: authStore.user?._id } : {};

  React.useEffect(() => {
    store.appeals.setSort(DEFAULT_SORT, true);
  }, []);

  React.useEffect(() => {
    if (newAppealsCountStore.count !== null && !isUserAppeals) {
      store.appeals.load();
    } else if (newAppealsCountStore.count !== null && isUserAppeals && authStore.user?._id) {
      store.appeals.setFilters({ filter: userAppealsFilters });
    }
  }, [newAppealsCountStore.count, authStore.user?._id]);

  const handleAppeals = ({ sort, filter }) => {
    if (sort) {
      store.appeals.setSort(sort, true);
    }
    if (filter) {
      store.appeals.setFilters({ filter: { ...filter, ...userAppealsFilters } }, true);
    }
    store.appeals.loadPage(1);
  };

  return (
    <div className="statement">
      <Filters
        categories={{}}
        fetchFilteredAppeals={handleAppeals}
      />
      <AppealsTableLayout
        appeals={store.appeals.getData}
        categories={{}}
        isFetching={store.appeals.isLoading}
        fetchFilteredAppeals={handleAppeals}
      />
      <div className="statement__footer">
        <Paginator
          totalPages={store.appeals.getTotalPages}
          currentPage={store.appeals.getCurrentPage}
          changePagination={(page) => store.appeals.loadPage(page)}
        />
      </div>
      <Footer />
      {store.appeals.isLoading ? <Loader /> : null}
    </div>
  );
});

export default AppealsView;
