export default (phone) => {
  if (!phone) {
    return '';
  }
  const cleaned = phone.toString().replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d+)(\d{7})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]}` : '';
    return [intlCode, ' - ', match[2], ' - ', match[3]].join('');
  }
  return phone;
};
