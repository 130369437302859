import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './DateInput.scss';

function DateInput({
  label,
  value,
  error,
  isDisabled,
  onChange,
}) {
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div
      className={classNames(
        'date-field',
        label && 'date-field_labeled',
        isFocus && 'date-field_focused',
        value && 'not-empty',
        !isFocus && error && value && 'invalid',
      )}
    >
      {label && <span className="date-field__label">{label}</span>}
      <InputMask
        mask="99.99.9999"
        maskPlaceholder={t('date placeholder')}
        alwaysShowMask={false}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        disabled={isDisabled}
        className="date-field__input"
        dir="ltr"
      />
    </div>
  );
}

DateInput.defaultProps = {
  label: null,
  value: '',
  error: false,
  isDisabled: false,
};

DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DateInput;
