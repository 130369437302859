import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import getImageBlobUrl from '../../requests/if_api/ImageBlob';

const Image = ({
  thumb, bigImageUrl, options, showPopup, imageClass, initialImageClass,
}) => {
  const [url, setURL] = useState(null);

  const urlRef = useRef(null);

  useEffect(async () => {
    try {
      if (thumb) {
        const imageBlobUrl = await getImageBlobUrl(thumb);
        setURL(imageBlobUrl);
        urlRef.current = imageBlobUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }, [thumb]);

  useEffect(() => () => {
    if (urlRef.current) {
      URL.revokeObjectURL(urlRef.current);
    }
  }, []);

  if (url) {
    return (
      <img
        aria-hidden
        className={imageClass}
        src={url}
        onClick={() => showPopup({ ...options, ...{ fileName: bigImageUrl } })}
        alt=""
      />
    );
  }
  return (<div className={initialImageClass} />);
};

Image.propTypes = {
  thumb: PropTypes.string.isRequired,
  bigImageUrl: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  showPopup: PropTypes.func.isRequired,
  imageClass: PropTypes.string.isRequired,
  initialImageClass: PropTypes.string.isRequired,
};

export default Image;
