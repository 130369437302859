import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../components/UI/Popup/Popup';
import { ReactComponent as IconClip } from '../../../../icons/svg/fa-clip.svg';
import { ReactComponent as IconClose } from '../../../../icons/svg/close.svg';
import { IMAGE_TYPES } from '../../../../constants';

import getResizedImg from '../../../../utils/getResizedImg';

const POPUP_DEFAULT_PROPS = {
  popupVisible: false,
  imageUrl: '',
  options: {
    firstname: '',
    lastname: '',
    createdAt: new Date().toString(),
    fileName: '',
    avatarURL: '',
  },
};

const checkFile = (file) => IMAGE_TYPES.includes(file.type);

const UploadImages = observer(({
  store, client, setIsResizingImages, isResizingImages, setAlertMessages,
}) => {
  const { t } = useTranslation();
  const inputRef = React.useRef();
  const [popup, setPopup] = React.useState(POPUP_DEFAULT_PROPS);

  const handleClick = () => {
    if (!isResizingImages) {
      inputRef.current.click();
    }
  };

  const handleChange = () => {
    const { files } = inputRef.current;
    const listPromises = [];

    if (files && files.length) {
      const alertMessages = [];
      for (let i = 0; i < files.length; i += 1) {
        if (checkFile(files[i])) {
          listPromises.push(getResizedImg(files[i]));
        } else {
          alertMessages.push({ text: `${files[i].name} ${t('Error type file')}`, id: Math.random().toString() });
        }
      }

      if (alertMessages.length) {
        setAlertMessages(alertMessages);
      }

      Promise.all(listPromises).then((results) => {
        store.addImages(results);
        setIsResizingImages(false);
      }).catch(() => setIsResizingImages(false));
    }
  };

  const handleImageClick = (image) => {
    setPopup({
      popupVisible: true,
      imageUrl: URL.createObjectURL(image),
      options: {
        firstname: client.firstname ?? '',
        lastname: client.lastname ?? '',
        createdAt: new Date().toString(),
        fileName: image.name,
        avatarURL: client.photo?.thumb ?? '',
      },
    });
  };

  const closePopup = () => setPopup(POPUP_DEFAULT_PROPS);

  return (
    <>
      <div className="create-statement__images">
        {store.images?.map((image) => (
          <div key={image.name} className="create-statement__image">
            <img
              src={URL.createObjectURL(image)}
              onClick={() => handleImageClick(image)}
              aria-hidden
              alt=""
            />
            <IconClose
              className="create-statement__remove-icon"
              onClick={() => store.removeImage(image)}
            />
          </div>
        ))}
      </div>

      <div
        onClick={handleClick}
        className="create-statement__add-photo"
        role="button"
        aria-hidden
      >
        <input ref={inputRef} onChange={handleChange} type="file" hidden multiple />
        <IconClip />
        {t('Select a photo')}
      </div>

      <Popup
        popupVisible={popup.popupVisible}
        imageUrl={popup.imageUrl}
        closePopup={closePopup}
        options={popup.options}
      />
    </>
  );
});

export default UploadImages;
