import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AccountStatus from '../../../../components/AccountStatus/AccountStatus';
import { ReactComponent as EditIcon } from '../../../../icons/svg/edit.svg';

function StatusCell({ status, row }) {
  const history = useHistory();

  const handleEditClick = (e) => {
    e.preventDefault();
    if (row.role === 'admin') {
      history.push(`/edit_admin/${row._id}`);
    } else {
      history.push(`/edit_user/${row._id}`);
    }
  };

  return (
    <div className="account-status__wrap">
      <AccountStatus status={status} />
      <EditIcon onClick={handleEditClick} />
    </div>
  );
}

StatusCell.defaultProps = {
  row: {},
};

StatusCell.propTypes = {
  status: PropTypes.string.isRequired,
  row: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    role: PropTypes.string,
  }),
};

export default StatusCell;
