import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AvatarEditor from '../../components/AvatarEditor/AvatarEditor';
import PassportInput from '../../components/PassportInput';
import DateInput from '../../components/DateInput';
import Input from '../../components/Input';
import PhoneInput from '../../components/PhoneInput';
import Loader from '../../components/UI/Loader/Loader';

import EditUserForm from '../../stores/views/editUserStore';

const VIEWS_TITLES = {
  CREATE: 'Create an account',
  EDIT: 'Edit an account',
};

const VIEWS_SUBMIT_BUTTON_LABELS = {
  SAVE: 'Save',
  CLOSE: 'Close',
};

const EditAccountView = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [store] = useState(() => new EditUserForm());
  const [isAvatarEditMode, setAvatarEditMode] = useState(false);
  const [isSaved, setIsSaved] = React.useState(false);

  useEffect(() => {
    store.getClient(id);
  }, [id]);

  const handleChange = (field) => (val) => store.setValue(field, val);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!store.isEdited) {
      setIsSaved(true);
    } else {
      const isSuccess = await store.saveClient();
      if (isSuccess) {
        setIsSaved(true);
      }
    }
  };

  if (isSaved && !store.isRequestLoading) {
    return <Redirect to={`/users/${store.client._id}`} />;
  }

  return (
    <>
      <form className="create-statement" onSubmit={handleSubmit}>
        <h3 className="create-statement__title">
          {t(id ? VIEWS_TITLES.EDIT : VIEWS_TITLES.CREATE)}
        </h3>

        <AvatarEditor
          person={store.client}
          isEditMode={isAvatarEditMode}
          handleEditMode={setAvatarEditMode}
          handleSubmit={store.uploadImage}
        />

        {!isAvatarEditMode && (
          <>
            <div className="create-statement__controls-wrapper">
              <Input
                label={t('Email')}
                value={store.formData.email ?? ''}
                error={store.isError('email')}
                onChange={handleChange('email')}
              />

              <Input
                label={t('Surname')}
                value={store.formData.lastname ?? ''}
                error={store.isError('lastname')}
                onChange={handleChange('lastname')}
              />

              <Input
                label={t('Name')}
                value={store.formData.firstname ?? ''}
                error={store.isError('firstname')}
                onChange={handleChange('firstname')}
              />

              <PhoneInput
                label={t('Phone number')}
                value={store.formData.phone}
                type={store.phoneType}
                error={store.isError('phone')}
                onChange={handleChange('phone')}
                onSubmit={() => { }}
                onChangeType={(type) => store.setPhoneType(type)}
              />

              <PassportInput
                label={t('Teudat Zehut number')}
                value={store.formData.passport ?? ''}
                error={store.isError('passport')}
                onChange={handleChange('passport')}
              />

              <DateInput
                label={t('Date of birth')}
                value={store.formData.birthDate ?? ''}
                error={store.isError('birth_date')}
                onChange={handleChange('birthDate')}
              />

              <Input
                label={t('Address')}
                value={store.formData.address ?? ''}
                error={store.isError('address')}
                onChange={handleChange('address')}
              />
            </div>

            <button
              type="submit"
              disabled={!store.isFormValid || store.isRequestLoading || isAvatarEditMode}
              className="button create-statement__button"
            >
              {t(store.isEdited
                ? VIEWS_SUBMIT_BUTTON_LABELS.SAVE
                : VIEWS_SUBMIT_BUTTON_LABELS.CLOSE)}
            </button>
          </>
        )}
      </form>

      { store.isRequestLoading && <Loader />}
    </>
  );
});

export default EditAccountView;
