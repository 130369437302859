import { MAX_IMAGE_SIZE } from '../constants';

export default (inputedBlob) => new Promise((resolve, reject) => {
  const image = new Image();

  image.onload = () => {
    let newImageHeight;
    let newImageWidth;

    if (image.naturalHeight > MAX_IMAGE_SIZE || image.naturalWidth > MAX_IMAGE_SIZE) {
      if (image.naturalHeight > image.naturalWidth) {
        newImageHeight = MAX_IMAGE_SIZE;
        newImageWidth = Math.ceil((image.naturalWidth / image.naturalHeight) * MAX_IMAGE_SIZE);
      } else {
        newImageWidth = MAX_IMAGE_SIZE;
        newImageHeight = Math.ceil((image.naturalHeight / image.naturalWidth) * MAX_IMAGE_SIZE);
      }

      const canvas = document.createElement('canvas');
      canvas.width = newImageWidth;
      canvas.height = newImageHeight;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        newImageWidth,
        newImageHeight,
      );

      canvas.toBlob((blob) => {
        URL.revokeObjectURL(url);
        canvas.remove();
        image.remove();

        if (!blob) {
          reject(new Error('Canvas is empty'));
        }

        const file = new File([blob], inputedBlob.name, { type: inputedBlob.type });
        resolve(file);
      }, inputedBlob.type);
    } else {
      URL.revokeObjectURL(url);
      image.remove();
      resolve(inputedBlob);
    }
  };

  const url = URL.createObjectURL(inputedBlob);
  image.src = url;
});
