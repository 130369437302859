import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Alert.scss';

const DURATION_VISIBILITY_MESSAGE = 10000; // ms

const Alert = ({
  messages,
}) => {
  const [alertMessages, setAlertMessages] = useState([]);

  const visibilityTimers = useRef([]);

  useEffect(() => {
    if (messages?.length) {
      const messagesToShow = [];
      for (let i = 0; i < messages.length; i += 1) {
        const timerId = closeMessageByTimer(messages[i].id);
        visibilityTimers.current.push(timerId);
        messagesToShow.push({ ...messages[i], ...{ timerId } });
      }
      setAlertMessages((prev) => [...prev, ...messagesToShow]);
    }
  }, [messages]);

  useEffect(() => () => {
    visibilityTimers.current.forEach((timerId) => clearTimeout(timerId));
  }, []);

  const isAlertVisible = () => (alertMessages.length > 0);

  const closeMessageHandler = (messageId) => {
    const messageToClose = alertMessages.find((message) => message.id === messageId);
    clearTimeout(messageToClose.timerId);
    setAlertMessages((prev) => prev.filter((message) => message.id !== messageId));
  };

  const closeMessageByTimer = (messageId) => setTimeout(() => {
    setAlertMessages((prev) => prev.filter((message) => message.id !== messageId));
  }, DURATION_VISIBILITY_MESSAGE);

  return (
    <div className={classNames('alert', isAlertVisible() && 'alert_visible')}>
      {alertMessages.map((message) => (
        <div className="alert__message" key={message.id}>
          { message.text }

          <span aria-hidden className="alert__icon-remove" onClick={() => closeMessageHandler(message.id)} />
        </div>
      ))}
    </div>
  );
};

Alert.defaultProps = {
  messages: [],
};

Alert.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default Alert;
