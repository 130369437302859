import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getFirstDateMonth = (date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  );
};

const getDefaultCalendarState = () => ({
  currentMonthOnCalendar: getFirstDateMonth(),
  visible: false,
  startRange: null,
  endRange: null,
  step: true,
  sendedDate: {
    startRange: null,
    endRange: null,
  },
});

const Calendar = ({ fetchFilteredData, dateFilterRange }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(() => getDefaultCalendarState());

  useEffect(() => {
    const { startRange, endRange } = dateFilterRange;

    if (startRange) {
      setState(() => (
        {
          currentMonthOnCalendar: getFirstDateMonth(startRange),
          visible: false,
          startRange,
          endRange,
          step: true,
          sendedDate: {
            startRange,
            endRange,
          },
        }
      ));
    } else {
      setState(() => getDefaultCalendarState());
    }
  }, [dateFilterRange]);

  const getVisibleOverlay = () => {
    if (state.visible) {
      return 'calendar__overlay';
    }
    return 'calendar__overlay calendar__overlay_hidden';
  };

  const getVisibleCalendar = () => {
    if (state.visible) {
      return 'calendar__wrapper';
    }
    return 'calendar__wrapper calendar__wrapper_hidden';
  };

  const monthText = `${
    t(months[state.currentMonthOnCalendar.getMonth()])
  } ${state.currentMonthOnCalendar.getFullYear()}`;

  const setMonth = (count) => {
    const newDate = new Date(state.currentMonthOnCalendar.getTime());
    newDate.setMonth(newDate.getMonth() + count);
    setState((prevState) => ({ ...prevState, ...{ currentMonthOnCalendar: newDate } }));
  };

  const clearDateRange = async () => {
    setState(() => getDefaultCalendarState());

    await fetchFilteredData({
      createdAt: {
        from: '',
        to: '',
      },
    });
  };

  const sendDateToFilter = async () => {
    let from = '';
    let to = '';
    let newDate;
    const { startRange, endRange } = state;

    if (startRange) {
      from = `${startRange.getFullYear()}-${startRange.getMonth() + 1}-${startRange.getDate()} 00:00`;

      if (endRange) {
        newDate = new Date(
          endRange.getFullYear(), endRange.getMonth(), endRange.getDate() + 1,
        );
      } else {
        newDate = new Date(
          startRange.getFullYear(),
          startRange.getMonth(),
          startRange.getDate() + 1,
        );
      }
      to = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()} 00:00`;
    }

    setState((prevState) => ({
      ...prevState,
      ...{
        visible: false,
        step: true,
        currentMonthOnCalendar: getFirstDateMonth(startRange),
        sendedDate: {
          startRange,
          endRange,
        },
      },
    }));

    await fetchFilteredData({
      createdAt: {
        from,
        to,
      },
    });
  };

  const clickCellHandler = (cellDate) => {
    const { startRange, endRange, step } = state;
    if (!startRange && !endRange) {
      setState((prevState) => ({
        ...prevState,
        ...{ startRange: cellDate },
      }));
      return;
    }

    if (startRange && !endRange) {
      if (cellDate.getTime() >= startRange.getTime()) {
        setState((prevState) => ({
          ...prevState,
          ...{ endRange: cellDate },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...{ startRange: cellDate, endRange: startRange },
        }));
      }
      return;
    }

    if (cellDate.getTime() < startRange.getTime()) {
      setState((prevState) => ({
        ...prevState,
        ...{ startRange: cellDate, step: false },
      }));
      return;
    }

    if (cellDate.getTime() > endRange.getTime()) {
      setState((prevState) => ({
        ...prevState,
        ...{ endRange: cellDate, step: true },
      }));
      return;
    }

    let newStartRange;
    let newEndRange;

    if (step) {
      newStartRange = cellDate;
      newEndRange = endRange;
    } else {
      newStartRange = startRange;
      newEndRange = cellDate;
    }

    if (newStartRange.getTime() > newEndRange.getTime()) {
      [newStartRange, newEndRange] = [newEndRange, newStartRange];
    }
    setState((prevState) => ({
      ...prevState,
      ...{ startRange: newStartRange, endRange: newEndRange, step: !step },
    }));
  };

  const renderCell = (cellDate) => {
    const { currentMonthOnCalendar, startRange, endRange } = state;
    let cls = ['calendar__day'];

    if (cellDate.getMonth() !== currentMonthOnCalendar.getMonth()) {
      cls.push('calendar__day_another-month');
    }

    if (cellDate.getDay() === 6) {
      cls.push('calendar__day_saturday');
    }

    if (startRange) {
      if (cellDate.getTime() === startRange.getTime()) {
        cls.push('calendar__day_selected');
        cls.push('calendar__day_selected-start');
      }
    }

    if (endRange) {
      if (cellDate.getTime() === endRange.getTime()) {
        cls.push('calendar__day_selected');
        cls.push('calendar__day_selected-end');
      }
    }

    if (startRange && endRange) {
      if (cellDate > startRange && cellDate < endRange) {
        cls.push('calendar__day_in-range');
      }

      if (
        startRange.getTime() === endRange.getTime()
          && startRange.getTime() === cellDate.getTime()
      ) {
        cls = cls.filter((c) => c !== 'calendar__day_selected-start' && c !== 'calendar__day_selected-end');
      }
    }

    return (
      <div aria-hidden onClick={() => clickCellHandler(cellDate)} key={cellDate.toString() + Date.now()} className={cls.join(' ')}>{cellDate.getDate()}</div>
    );
  };

  const renderRow = (day) => {
    const cellsArray = new Array(7).fill(0);
    const { currentMonthOnCalendar } = state;

    return (
      <div className="calendar__days-row" key={(new Date()).toString() + day}>
        {cellsArray.map(() => {
          const date = new Date(
            currentMonthOnCalendar.getFullYear(),
            currentMonthOnCalendar.getMonth(),
            day,
          );
          day += 1;
          return renderCell(date);
        })}
      </div>
    );
  };

  const renderCalendar = () => {
    const { currentMonthOnCalendar } = state;
    const firstDayMonth = currentMonthOnCalendar.getDay();
    const rowsArray = [];
    let countRow = 5;

    if (
      new Date(
        currentMonthOnCalendar.getFullYear(),
        currentMonthOnCalendar.getMonth(),
        36 - firstDayMonth,
      ).getMonth() === currentMonthOnCalendar.getMonth()
    ) {
      countRow = 6;
    }

    for (let i = 0; i < countRow; i += 1) {
      rowsArray.push(1 - firstDayMonth + i * 7);
    }

    return (
      <div className="calendar__days-wrapper">
        {rowsArray.map((firstDateRow) => renderRow(firstDateRow))}
      </div>
    );
  };

  const hideCalendar = () => {
    const { sendedDate } = state;
    setState((prevState) => ({
      ...prevState,
      ...{
        visible: false,
        startRange: sendedDate.startRange,
        endRange: sendedDate.endRange,
        currentMonthOnCalendar: getFirstDateMonth(sendedDate.startRange),
      },
    }));
  };

  const renderStartedButton = () => {
    const { startRange, endRange } = state;

    let dateFilterText = t('Date');

    if (
      (startRange && !endRange) || (startRange && endRange && moment(startRange).isSame(endRange))
    ) {
      dateFilterText = `${moment(startRange).format('DD.MM.YY')}`;
    }

    if (startRange && endRange && !moment(startRange).isSame(endRange)) {
      dateFilterText = `${moment(startRange).format('DD.MM.YY')} - ${moment(endRange).format('DD.MM.YY')}`;
    }

    return (
      <button type="button" className="calendar__button" onClick={() => setState((prev) => ({ ...prev, ...{ visible: true } }))}>
        {dateFilterText}

        <span className="calendar__icon calendar__icon_calendar" />
      </button>
    );
  };

  return (
    <div className={classNames('calendar', `calendar_${i18next.dir()}`)}>
      {renderStartedButton()}
      <div className={getVisibleCalendar()}>
        <div className="calendar__month">
          <button
            type="button"
            className="calendar__button-control"
            onClick={() => setMonth(-1)}
          >
            <span className="calendar__icon calendar__icon_arrow-next calendar__icon_arrow-prev" />
          </button>

          <span className="calendar__date-text">{monthText}</span>

          <button type="button" className="calendar__button-control" onClick={() => setMonth(1)}>
            <span className="calendar__icon calendar__icon_arrow-next" />
          </button>
        </div>

        <div className="calendar__days-of-week">
          <div className="calendar__day calendar__day_week">{t('Sun')}</div>

          <div className="calendar__day calendar__day_week">{t('Mon')}</div>

          <div className="calendar__day calendar__day_week">{t('Tue')}</div>

          <div className="calendar__day calendar__day_week">{t('Wed')}</div>

          <div className="calendar__day calendar__day_week">{t('Thu')}</div>

          <div className="calendar__day calendar__day_week">{t('Fri')}</div>

          <div className="calendar__day calendar__day_week">{t('Sat')}</div>
        </div>

        {renderCalendar()}

        <div className="calendar__button-wrapper">
          <button type="button" className="calendar__button-success" onClick={sendDateToFilter}>{t('OK')}</button>

          <button type="button" className="calendar__button-success calendar__button-cancel" onClick={clearDateRange}>{t('Reset')}</button>
        </div>
      </div>

      <div aria-hidden className={getVisibleOverlay()} onClick={hideCalendar} />
    </div>
  );
};

Calendar.defaultProps = {
  dateFilterRange: {
    startRange: null,
    endRange: null,
  },
};

Calendar.propTypes = {
  fetchFilteredData: PropTypes.func.isRequired,
  dateFilterRange: PropTypes.objectOf(PropTypes.instanceOf(Date)),
};

export default Calendar;
