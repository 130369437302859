/* eslint-disable prefer-destructuring */
import axios from 'axios';
import config from '../../config.json';
import History from '../../routes/History';

import globalAppStore from '../../stores/globalAppStore';

axios.interceptors.request.use(
  (options) => {
    const token = globalAppStore.token;
    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = globalAppStore.token;
    if (error.response.status === 401) {
      if (token) {
        globalAppStore.setToken(null);
      }
      History.push('/login');
    }
    return Promise.reject(error);
  },
);

const api = {
  login: (body) => axios.post(`${config.API.URL}/users/login`, body),
  getAppeals: (params) => axios.get(`${config.API.URL}/appeals`, { params }),
  getCategories: () => axios.get(`${config.API.URL}/categories`),
  getAppeal: (id) => axios.get(`${config.API.URL}/appeals/${id}`),
  getUsers: () => axios.get(`${config.API.URL}/users`),
  getImage: (url, options) => axios.get(`${url}`, options),
  setAppealStatus: (id, body) => axios.post(`${config.API.URL}/appeals/${id}/status`, body),
  setAppealModerator: (id, body) => axios.post(`${config.API.URL}/appeals/${id}/assign`, body),
  sendAppealComment: (id, body) => axios.post(`${config.API.URL}/appeals/${id}/comment`, body),
  redirectAppeal: (id, body) => axios.post(`${config.API.URL}/appeals/${id}/resend`, body),
};

export const doRequest = (url, options) => axios.get(`${config.API.URL}/${url}`, options);
export const doUrlRequest = (url, options) => axios.get(url, options);
export const doPatch = (url, options) => axios.patch(`${config.API.URL}/${url}`, options);
export const doPost = (url, options) => axios.post(`${config.API.URL}/${url}`, options);
export const doUploadFiles = (url, options, headers) => axios.post(`${config.API.URL}/${url}`, options, headers);

export default api;
