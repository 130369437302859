import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import authStore from '../../../stores/authStore';
import getResizedImg from '../../../utils/getResizedImg';

import { IMAGE_TYPES, MAX_FILES_TO_COMMENT, DOCUMENT_MIME_TYPES } from '../../../constants';

import { ReactComponent as IconPdfFile } from '../../../icons/svg/pdf.svg';
import { ReactComponent as IconClose } from '../../../icons/svg/close.svg';

const isImageFile = (file) => IMAGE_TYPES.includes(file.type);
const isDocumentFile = (file) => DOCUMENT_MIME_TYPES.includes(file.type);

const Modal = observer(({
  modalVisible,
  closeModal,
  title,
  cancelButtonText,
  successButtonText,
  callbackFunction,
  showPopup,
  showAlert,
  canAddAttachments,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  const [commentAttachments, setCommentAttachments] = useState([]);

  const [isResizingImages, setIsResizingImages] = useState(false);

  const inputRef = useRef(null);

  const clickInputFilesHandler = () => {
    if (!isResizingImages) {
      inputRef.current.click();
    }
  };

  const currentUser = authStore.user;

  const renderFiles = () => {
    if (commentAttachments.length) {
      const options = {
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        createdAt: new Date().toString(),
        avatarURL: currentUser?.photo?.thumb,
      };

      return (
        <div className="comment-input__files-wrapper">
          {commentAttachments.map((file) => {
            const { lastModifiedDate, name } = file;

            if (isDocumentFile(file)) {
              return (
                <div key={`${lastModifiedDate} ${name} ${Math.random()}`} className="comment-input__document">
                  <div className="comment-input__document-name">
                    <IconPdfFile />
                    {file.name}
                  </div>

                  <IconClose
                    className="comment-input__document-close"
                    onClick={(event) => removeAttachmentHandler(event, file)}
                  />
                </div>
              );
            }

            const imageURL = URL.createObjectURL(file);

            return (
              <div
                className="comment-input__file"
                key={`${lastModifiedDate} ${name} ${Math.random()}`}
                onClick={() => showPopup({ ...options, ...{ fileName: imageURL } }, false)}
                role="button"
                aria-hidden
              >
                <img className="comment-input__image" src={imageURL} alt="" />

                <span
                  className="comment-input__icon-remove"
                  onClick={(event) => removeAttachmentHandler(event, file, imageURL)}
                  role="button"
                  aria-hidden
                />
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const addAttachmentsHandler = () => {
    if (commentAttachments.length >= MAX_FILES_TO_COMMENT) return;

    const { files } = inputRef.current;

    Array.from(files).forEach((file) => {
      if (isImageFile(file)) {
        setIsResizingImages(true);
        getResizedImg(file)
          .then((img) => {
            setCommentAttachments((prev) => [...prev, img]);
            setIsResizingImages(false);
          })
          .catch(() => setIsResizingImages(false));
      } else if (isDocumentFile(file)) {
        setCommentAttachments((prev) => [...prev, file]);
      } else {
        showAlert([{
          text: `${file.name} ${t('Error type file')}`,
          id: Math.random().toString(),
        }]);
      }
    });

    inputRef.current.value = '';
  };

  const removeAttachmentHandler = (event, removedFile, imageURL) => {
    event.stopPropagation();

    setCommentAttachments((prev) => (prev.filter((file) => file !== removedFile)));

    if (imageURL) {
      URL.revokeObjectURL(imageURL);
    }
  };

  const getStatementDisabledButton = () => {
    if (value.trim()) {
      return 'modal__button button';
    }
    return 'modal__button button button_disabled';
  };

  const inputChangeHanlder = (event) => setValue(event.target.value);

  const closeModalHandler = () => {
    setValue('');
    setCommentAttachments([]);
    closeModal();
  };

  const successButtonHandler = async () => {
    if (value.trim() === '' || isResizingImages) return;
    await callbackFunction(value, commentAttachments);
    closeModal();
  };

  const submitHandler = (event) => event.preventDefault();

  return (
    <>
      <form onSubmit={submitHandler} className={classNames('modal', modalVisible && 'modal_visible')}>
        <div className="modal__title">
          { title }

          <span aria-hidden className="popup__icon-remove" onClick={closeModalHandler} />
        </div>

        <input className="modal__input" value={value} onChange={inputChangeHanlder} />

        <div className="modal__buttons-row">
          {(canAddAttachments && commentAttachments.length < MAX_FILES_TO_COMMENT)
            ? (
              <span
                className="comment-input__icon modal__icon"
                onClick={clickInputFilesHandler}
                role="button"
                aria-hidden
              />
            )
            : null}

          <input type="file" hidden ref={inputRef} onChange={addAttachmentsHandler} />

          <div className="modal__buttons-wrapper">
            <button onClick={successButtonHandler} type="button" className={getStatementDisabledButton()}>{successButtonText}</button>

            <button onClick={closeModalHandler} type="button" className="button button_border-less">{cancelButtonText}</button>
          </div>
        </div>

        {renderFiles()}
      </form>

      <div aria-hidden className={classNames('modal__shadow', modalVisible && 'modal__shadow_visible')} onClick={closeModalHandler} />
    </>
  );
});

Modal.defaultProps = {
  title: '',
  modalVisible: false,
  cancelButtonText: '',
  successButtonText: '',
  showPopup: () => {},
  showAlert: () => {},
  canAddAttachments: false,
};

Modal.propTypes = {
  modalVisible: PropTypes.bool,
  title: PropTypes.string,
  cancelButtonText: PropTypes.string,
  successButtonText: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  callbackFunction: PropTypes.func.isRequired,
  showPopup: PropTypes.func,
  showAlert: PropTypes.func,
  canAddAttachments: PropTypes.bool,
};

export default Modal;
