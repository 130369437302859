import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { ReactComponent as IconNewCommentBadge } from '../../icons/svg/new-comment-badge.svg';
import UnreadCommentsStore from '../../stores/webSockets/unreadCommentsStore';

const NewCommentBadge = observer(({ appealId }) => {
  const [store] = React.useState(() => new UnreadCommentsStore());

  React.useEffect(() => {
    if (appealId) {
      store.init(appealId);
    }

    return () => store.unsubscribe();
  }, [appealId]);

  if (!store.count) {
    return null;
  }

  return <IconNewCommentBadge />;
});

NewCommentBadge.propTypes = {
  appealId: PropTypes.string.isRequired,
};

export default NewCommentBadge;
