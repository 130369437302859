export default {
  clients: [
    {
      name: 'person',
      label: 'Name and Surname',
      fields: ['firstname', 'lastname'],
    },
    { name: 'phone', label: 'Phone number', fields: 'phone' },
    { name: 'email', label: 'Email', fields: 'email' },
    { name: 'address', label: 'Address', fields: 'address' },
    { name: 'status', label: 'Status', fields: 'status' },
  ],
  users: [
    {
      name: 'person',
      label: 'Name and Surname',
      fields: ['firstname', 'lastname'],
    },
    { name: 'phone', label: 'Phone number', fields: 'phone' },
    { name: 'email', label: 'Email', fields: 'email' },
    { name: 'status', label: 'Status', fields: 'status' },
  ],
};
