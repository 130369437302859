import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './AppealStatus.scss';

const APPEAL_STATUSES = {
  new: { label: 'New', color: 'danger' },
  processing: { label: 'In progress' },
  working: { label: 'Pending' },
  rejected: { label: 'Rejected' },
  done: { label: 'Done', color: 'success' },
  finished: { label: 'Completed', color: 'success' },
};

function AppealStatus({ status }) {
  const { t } = useTranslation();
  const { color, label } = APPEAL_STATUSES?.[status] || {};

  return (
    <span
      className={classNames('appeal-status', color && `appeal-status_${color}`)}
    >
      {t(label)}
    </span>
  );
}

export default AppealStatus;

AppealStatus.defaultProps = {
  status: '',
};

AppealStatus.propTypes = {
  status: PropTypes.string,
};
