import React from 'react';
import PropTypes from 'prop-types';

function Paginator({ totalPages, currentPage, changePagination }) {
  if (totalPages === 0) {
    return null;
  }

  const createArrayPages = () => {
    if (totalPages === 1) {
      return [1];
    }
    if (totalPages === 2) {
      return [1, 2];
    }
    if (currentPage === 1) {
      return [1, 2, 3];
    }
    if (totalPages === currentPage) {
      return [currentPage - 2, currentPage - 1, currentPage];
    }
    return [currentPage - 1, currentPage, currentPage + 1];
  };
  const prevArrow = () => {
    const cls = ['pagination__item-link', 'pagination__item-link_arrow'];
    if (currentPage === 1) {
      cls.push('pagination__item-link_disabled');
    }
    return (
      <li className="pagination__item" key={Math.random()}>
        <a href="/" className={cls.join(' ')} onClick={goToPrevPage}>
          <span className="pagination__icon pagination__icon_prev-arrow" />
        </a>
      </li>
    );
  };
  const nextArrow = () => {
    const cls = ['pagination__item-link', 'pagination__item-link_arrow'];
    if (currentPage === totalPages) {
      cls.push('pagination__item-link_disabled');
    }
    return (
      <li className="pagination__item" key={Math.random()}>
        <a href="/" className={cls.join(' ')} onClick={goToNextPage}>
          <span className="pagination__icon pagination__icon_next-arrow" />
        </a>
      </li>
    );
  };
  const goToPage = (event, page) => {
    event.preventDefault();
    if (totalPages > 1 && currentPage !== page) {
      changePagination(page);
    }
  };
  const goToPrevPage = (event) => {
    event.preventDefault();
    if (currentPage > 1) {
      changePagination(currentPage - 1);
    }
  };
  const goToNextPage = (event) => {
    event.preventDefault();
    if (currentPage < totalPages) {
      changePagination(currentPage + 1);
    }
  };
  return (
    <nav className="pagination" dir="ltr">
      <ul className="pagination__list-items">
        {prevArrow()}
        {
          createArrayPages().map((page) => {
            const cls = ['pagination__item-link'];
            if (page === currentPage) {
              cls.push('pagination__item-link_active');
            }
            return (
              <li className="pagination__item" key={Math.random()}>
                <a href="/" className={cls.join(' ')} onClick={(event) => goToPage(event, page)}>{ page }</a>
              </li>
            );
          })
        }
        {nextArrow()}
      </ul>
    </nav>
  );
}

Paginator.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  changePagination: PropTypes.func.isRequired,
};

export default Paginator;
