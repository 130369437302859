import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_KEY } from '../../../config.json';

const MapIcon = () => <span className="appeal-map__icon" />;

const AppealMap = ({ coordinatesOfIncident }) => (
  <div className="appeal-map">
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAP_KEY, region: 'IL' }}
      center={coordinatesOfIncident}
      zoom={15}
    >
      <MapIcon
        lat={coordinatesOfIncident.lat}
        lng={coordinatesOfIncident.lng}
      />
    </GoogleMapReact>
  </div>
);

AppealMap.defaultProps = {
  coordinatesOfIncident: {
    lat: 31.6816569,
    lng: 34.55717870000001,
  },
};

AppealMap.propTypes = {
  coordinatesOfIncident: PropTypes.objectOf(PropTypes.number),
};

export default AppealMap;
