import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import categoryStore from '../../../stores/categoryStore';
import AppealsStore from '../../../stores/appealStore';
import Select from '../../Select';
import { categories } from '../../../constants';

const appealsStore = new AppealsStore();

const getValue = (value) => ({
  id: value,
  label: categories[value],
});

const CategorySelect = observer(({
  appeal,
  value,
  size,
  isDisabled,
  onChange,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    appealsStore.setAppeal(appeal);
  }, [appeal]);

  const handleChange = (val) => {
    if (typeof onChange === 'function') {
      onChange(val.id);
    } else {
      appealsStore.patchAppeal(appeal._id, { category: val.id });
    }
  };

  return (
    <Select
      label={t('Category')}
      value={getValue(value || appealsStore?.appeal?.category)}
      valueList={categoryStore.getNormalizedCategories}
      size={size}
      isDisabled={isDisabled}
      handleChange={handleChange}
    />
  );
});

CategorySelect.defaultProps = {
  value: null,
  size: '',
  isDisabled: false,
  onChange: null,
};

CategorySelect.propTypes = {
  appeal: PropTypes.shape({
    status: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    no: PropTypes.string,
    createdAt: PropTypes.string,
    category: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.object),
    client: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  }),
  value: PropTypes.string,
  size: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CategorySelect;
