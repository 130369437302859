import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import appealRequests from '../../requests/if_api/Appeal';
import usersRequests from '../../requests/if_api/Users';
import getImageBlobUrl from '../../requests/if_api/ImageBlob';

import AppealData from './AppealData/AppealData';
import Chat from './Chat';
import Footer from '../UI/Footer/Footer';
import Loader from '../UI/Loader/Loader';
import Modal from '../UI/Modal';
import Popup from '../UI/Popup/Popup';
import PDFViewer from '../PDFViewer';
import Alert from '../Alert';
import { doUploadFiles } from '../../requests/if_api/IFApi';

const defaultPopup = {
  popupVisible: false,
  imageUrl: '',
  options: {
    firstname: '',
    lastname: '',
    createdAt: new Date().toString(),
    fileName: '',
    avatarURL: '',
  },
};

const uploadAttachments = async (commentId, attachments) => {
  try {
    const formData = new FormData();
    attachments.forEach((attachment) => formData.append('files', attachment));
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await doUploadFiles(`appeals/comment/${commentId}/attachments`, formData, { headers });
    return response;
  } catch (e) {
    throw new Error('Error uploading file');
  }
};

class Appeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appeal: {},
      isFetching: false,
      listCategories: {},
      users: [],
      responsibleId: 'defaultValue',
      popup: defaultPopup,
      modalVisible: false,
      pdfUrl: null,
      alertMessages: [],
    };
    this.changeSelect = this.changeSelect.bind(this);
    this.changeStatusHandler = this.changeStatusHandler.bind(this);
    this.sendComment = this.sendComment.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.sendRequiredComment = this.sendRequiredComment.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.sendToDepartment = this.sendToDepartment.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.fetchAppeal(match.params.id);
  }

  async fetchAppeal(id) {
    try {
      this.setState((prevState) => ({ ...prevState, isFetching: true }));
      const response = await appealRequests.getAppeal(id);
      const users = await usersRequests.list();
      const responsibleId = response.data.responsible?._id || 'defaultValue';
      this.setState({
        appeal: response.data,
        isFetching: false,
        users: users.data,
        responsibleId,
      });
    } catch (e) {
      console.error(e);
      this.setState((prevState) => ({ ...prevState, isFetching: false }));
    }
  }

  async changeSelect(item) {
    try {
      const { appeal, isFetching } = this.state;
      if (isFetching) return;
      this.setState((prevState) => ({ ...prevState, isFetching: true }));
      const responsibleId = item?.id;
      await appealRequests.setAppealModerator(appeal._id, responsibleId);
      this.setState((prevState) => ({
        ...prevState, isFetching: false, responsibleId,
      }));
    } catch (e) {
      console.error(e);
      this.setState((prevState) => ({ ...prevState, isFetching: false }));
    }
  }

  async changeStatusHandler(status) {
    try {
      const { appeal, isFetching } = this.state;
      if (isFetching) return;
      this.setState((prevState) => ({ ...prevState, isFetching: true }));
      const response = await appealRequests.setAppealStatus(appeal._id, status);
      this.setState((prevState) => ({ ...prevState, isFetching: false, appeal: response.data }));
    } catch (e) {
      console.error(e);
      this.setState((prevState) => ({ ...prevState, isFetching: false }));
    }
  }

  async sendComment(id, value, commentAttachments) {
    try {
      const { isFetching } = this.state;
      if (isFetching) return;
      this.setState((prevState) => ({ ...prevState, isFetching: true }));
      let response = await appealRequests.sendAppealComment(id, value);

      const { comments } = response.data;
      const commentId = comments[comments.length - 1]._id;

      if (commentAttachments?.length) {
        response = await uploadAttachments(commentId, commentAttachments);
      }

      this.setState((prevState) => ({ ...prevState, isFetching: false, appeal: response.data }));
    } catch (e) {
      console.error(e);

      if (e.message === 'Error uploading file') {
        const { t } = this.props;
        this.showAlert([{ text: t(e.message), id: Math.random().toString() }]);
      }

      this.setState((prevState) => ({ ...prevState, isFetching: false }));
    }
  }

  async sendRequiredComment(value, commentAttachments) {
    const { appeal, newStatus } = this.state;

    await this.sendComment(appeal._id, value, commentAttachments);

    await this.changeStatusHandler(newStatus);
  }

  async sendToDepartment(directedTo) {
    const { appeal } = this.state;

    try {
      const { isFetching } = this.state;
      if (isFetching) return;
      this.setState((prevState) => ({ ...prevState, isFetching: true }));
      const response = await appealRequests.redirectAppeal(
        appeal._id,
        directedTo,
      );

      this.setState((prevState) => ({
        ...prevState,
        isFetching: false,
        appeal: response.data,
      }));
    } catch (e) {
      console.error(e);
      this.setState((prevState) => ({ ...prevState, isFetching: false }));
    }
  }

  async showPopup(options, requestFileFromServer = true) {
    try {
      const { isFetching } = this.state;
      if (isFetching) return;
      this.setState((prevState) => ({ ...prevState, isFetching: true }));

      let imageUrl;
      if (requestFileFromServer) {
        imageUrl = await getImageBlobUrl(options.fileName);
      } else {
        imageUrl = options.fileName;
      }

      const popup = {
        imageUrl,
        popupVisible: true,
        options,
      };

      this.setState((prevState) => ({ ...prevState, popup, isFetching: false }));
    } catch (e) {
      console.error(e);
      this.setState((prevState) => ({ ...prevState, isFetching: false }));
    }
  }

  closePopup() {
    const { popup } = this.state;

    const { imageUrl, options } = popup;

    if (options.isRevokeObjectURL) {
      URL.revokeObjectURL(imageUrl);
    }

    this.setState((prevState) => ({ ...prevState, popup: defaultPopup }));
  }

  closeModal() {
    this.setState((prevState) => ({ ...prevState, modalVisible: false }));
  }

  showModal(newStatus) {
    this.setState((prevState) => ({ ...prevState, modalVisible: true, newStatus }));
  }

  showAlert(alertMessages) {
    this.setState((prevState) => ({ ...prevState, alertMessages }));
  }

  render() {
    const {
      appeal, users, responsibleId, popup, isFetching, modalVisible, pdfUrl, alertMessages,
    } = this.state;

    const { t } = this.props;

    return (
      <div className="appeal">
        <AppealData
          appeal={appeal}
          users={users}
          responsibleId={responsibleId}
          changeStatusHandler={this.changeStatusHandler}
          changeSelect={this.changeSelect}
          showPopup={this.showPopup}
          showModal={this.showModal}
          sendToDepartment={this.sendToDepartment}
        />

        <div className="appeal__row">
          <Chat
            id={appeal._id}
            comments={appeal.comments}
            sendComment={this.sendComment}
            showPopup={this.showPopup}
            showPDF={(url) => this.setState({ pdfUrl: url })}
            showAlert={this.showAlert}
          />
        </div>
        <Popup
          popupVisible={popup.popupVisible}
          imageUrl={popup.imageUrl}
          closePopup={this.closePopup}
          options={popup.options}
        />
        <Modal
          title={t('Enter reason of closing appeal')}
          modalVisible={modalVisible}
          closeModal={this.closeModal}
          callbackFunction={this.sendRequiredComment}
          showPopup={this.showPopup}
          showAlert={this.showAlert}
          cancelButtonText={t('Close window')}
          successButtonText={t('Proceed')}
          canAddAttachments
        />
        <Alert
          messages={alertMessages}
        />
        { pdfUrl
          ? <PDFViewer url={pdfUrl} handleClose={() => this.setState({ pdfUrl: null })} />
          : null }
        { isFetching ? <Loader /> : null }
        <Footer />
      </div>
    );
  }
}

Appeal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(Appeal));
