import { makeAutoObservable } from 'mobx';

class TabsStore {
  tabs = [];

  currentTab = 0;

  constructor(tabs = []) {
    this.tabs = tabs;
    makeAutoObservable(this);
  }

  setCurrentTab = (tab) => {
    this.currentTab = tab;
  };

  isCurrentTab(tab) {
    return this.currentTab === tab;
  }

  get getCurrentTab() {
    return this.tabs?.[this.currentTab];
  }

  get getAddLink() {
    const { addLink, addLinkLabel, disabled } = this.tabs[this.currentTab];

    return {
      label: addLinkLabel,
      url: addLink,
      disabled,
    };
  }
}

export default TabsStore;
